import { gql } from "@apollo/client"

export const CandidatePreferencesMeFragment = gql`
    fragment CandidatePreferencesMe_user on BaseUserInterface {
        id
        isOpenToRemote
        currentCity {
            id
            geonameId
            name
            longLabel
            shortLabel
        }
        selectedCities {
            id
            geonameId
            name
            longLabel
            shortLabel
        }
        selectedExperience {
            id
            slug
            name
        }
        selectedJobTypes {
            edges {
                node {
                    id
                    name
                    slug
                }
            }
        }
        currentJobType {
            id
            name
            slug
            experienceName
        }
        yearsOfExperience
        selectedLocations {
            edges {
                node {
                    id
                    fullName
                    shortName
                }
            }
        }
        selectedWorkTypes {
            id
            name
            slug
        }
        selectedCompanySizes {
            id
            name
            slug
        }
        selectedIndustries {
            id
            name
            slug
        }
        authorizedForUs
        requiresSponsorshipForUs
        preferencesSummary
    }
`

export const CANDIDATE_PREFERENCES_QUERY = gql`
    query CandidatePreferenceQuery {
        experience {
            id
            slug
            name
        }
        locations {
            id
            fullName
            shortName
        }
        jobTypes {
            id
            slug
            name
        }
        workTypes {
            id
            name
            slug
        }
        companySizes {
            id
            name
            slug
        }
        industries {
            id
            name
            slug
        }
        jobTypeCategoriesCoreOnboarding {
            id
            name
            jobTypes {
                id
                name
                slug
            }
        }
        supportedCurrencies {
            label
            value
        }
        me {
            ...CandidatePreferencesMe_user
        }
    }
    ${CandidatePreferencesMeFragment}
`

export const JobBoardPreferencesMeFragment = gql`
    fragment JobBoardPreferencesMe_user on BaseUserInterface {
        id
        selectedJobBoardExperiences {
            id
            slug
            name
        }
        selectedJobBoardJobTypes {
            edges {
                node {
                    id
                    name
                    slug
                }
            }
        }
        selectedJobBoardLocations {
            edges {
                node {
                    id
                    fullName
                    shortName
                }
            }
        }
        selectedJobBoardLocations2 {
            id
            geonameId
            locationType
            shortLabel
            longLabel
        }
        selectedJobBoardIsOpenToRemote
    }
`

export const JOB_BOARD_PREFERENCES_QUERY = gql`
    query JobBoardPreferenceQuery {
        experience {
            id
            slug
            name
        }
        locations {
            id
            fullName
            shortName
        }
        jobTypes {
            id
            slug
            name
        }
        me {
            id
            ...JobBoardPreferencesMe_user
        }
    }
    ${JobBoardPreferencesMeFragment}
`

export const UPDATE_JOB_PREFERENCES_MUTATION = gql`
    mutation UpdateJobPreferences(
        $selectedExperience: [String!]!
        $jobTypes: [String!]!
        $selectedLocations: [String!]
        $selectedLocations2: [LocationInputType!]
        $isOpenToRemote: Boolean
    ) {
        updatePreferences(
            selectedLocations: $selectedLocations
            selectedExperience: $selectedExperience
            selectedJobTypes: $jobTypes
            selectedLocations2: $selectedLocations2
            isOpenToRemote: $isOpenToRemote
        ) {
            user {
                id
                ...JobBoardPreferencesMe_user
            }
            ok
        }
    }
    ${JobBoardPreferencesMeFragment}
`

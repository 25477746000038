import { useEffect, useState, useCallback } from "react"
import { useSessionStorage } from "utils/hooks/useSessionStorage"
import { usePalletRouter } from "utils/hooks/usePalletRouter"

const SESSION_STORAGE_KEY = "talent.manage.manageviewfilter"

type Filter = {
    id: number
    text: string
    hasIndicator: boolean
    term: string
}

const MEMBER_APPS = {
    id: 1,
    text: "Member applications",
    hasIndicator: false,
    term: "application",
}

const COMPANY_APPS = {
    id: 2,
    text: "Company applications",
    hasIndicator: false,
    term: "application",
}

const ACTIVE_MEMBERS = {
    id: 3,
    text: "Active members",
    hasIndicator: false,
    term: "member",
}

const ACTIVE_COMPANIES = {
    id: 4,
    text: "Active companies",
    hasIndicator: false,
    term: "company",
}

/**
 * This custom hook is meant to encapsulate the logic for the client side filters that
 * control the /talent/manage view. The hook abstracts over storage of the currently
 * selected filter, and handles coupled logic that has to happen on change of filter.
 */
export const useManageViewFilters = (
    initialFilter?: number,
    onlyJobBoard?: boolean
) => {
    const FILTERS: Filter[] = onlyJobBoard
        ? [COMPANY_APPS, ACTIVE_COMPANIES]
        : [MEMBER_APPS, COMPANY_APPS, ACTIVE_MEMBERS, ACTIVE_COMPANIES]

    const DEFAULT_FILTER = onlyJobBoard ? 2 : 1

    const VALID_FILTER_IDS = onlyJobBoard ? [2, 4] : [1, 2, 3, 4]

    const parseFilterValue = (value: string): number => {
        let filter = parseInt(value)
        if (!filter || !VALID_FILTER_IDS.includes(filter)) {
            filter = DEFAULT_FILTER
        }
        return filter
    }

    const router = usePalletRouter()
    const [_filters, _setFilters] = useState(FILTERS)
    const [_persistedFilter, _setPersistedFilter] =
        useSessionStorage(SESSION_STORAGE_KEY)
    const [_currentFilter, _setCurrentFilter] = useState<number | null>(null)

    /**
     * A handler that takes a filterId, and sets it as the currently selected filter.
     * The currently selected filter will be persisted to session storage, and reflected
     * upon the pages query params.
     * @param filterId
     */
    const changeFilterHandler = (filterId: number): void => {
        _setCurrentFilter(filterId)
        _setPersistedFilter(filterId.toString())
        router.push(
            { pathname: router.pathname, query: { filter: filterId } },
            undefined,
            { shallow: true, scroll: false }
        )
    }

    /**
     * A helper to clear indicators of all filters.
     */
    const clearFilterIndicators = useCallback(() => {
        _setFilters(prevFilters => {
            return prevFilters.map(filter => {
                return { ...filter, hasIndicator: false }
            })
        })
    }, [])

    /**
     * A helper to set the indicator of whether view under filter is non-empty.
     */
    const setFilterIndicator = useCallback((filterId: number) => {
        _setFilters(prevFilters => {
            return prevFilters.map(filter => {
                if (filter.id === filterId) {
                    return { ...filter, hasIndicator: true }
                } else {
                    return filter
                }
            })
        })
    }, [])

    useEffect(() => {
        let initial = initialFilter || DEFAULT_FILTER
        if (_persistedFilter) {
            initial = parseFilterValue(_persistedFilter)
        }
        _setCurrentFilter(initial)
        router.push(
            {
                pathname: router.pathname,
                query: { ...router.query, filter: initial },
            },
            undefined,
            { shallow: true }
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        currentFilter: _currentFilter,
        changeFilterHandler,
        setFilterIndicator,
        filters: _filters,
        clearFilterIndicators,
    }
}

import { TextInputField, TextInputFieldProps } from "@pallet-hq/hegel"
import * as Yup from "yup"

const FIELD_NAME = "email" as const

const EmailField = (props: TextInputFieldProps) => {
    return (
        <TextInputField
            label="Email"
            placeholder="steve.jobs@apple.com"
            type="email"
            name={FIELD_NAME}
            {...props}
        />
    )
}

EmailField.fieldName = FIELD_NAME
EmailField.validators = {
    required: {
        [FIELD_NAME]: Yup.string()
            .required("Required")
            .email("Please enter a valid email"),
    },
}

export { EmailField }

import { useQuery } from "@apollo/client"
import constate from "constate"
import { useCallback } from "react"
import { FEATURES_QUERY } from "utils/featureFlags"
import { FeaturesQuery } from "__generated__/FeaturesQuery"

/**
 * This custom hook queries for supported flags, and returns a utility function
   to determine whether a given flag is active or not.
 */
const useFeatureFlags = () => {
    const { data, loading, error } = useQuery<FeaturesQuery>(FEATURES_QUERY)

    const flagIsActive = useCallback(
        (name: string): boolean => {
            if (loading || error || !data) {
                return false
            }

            let feature = data.features.find(feature => feature.name === name)
            if (!feature) {
                console.warn("The specified feature is not supported.")
                return false
            }
            return feature.isActive
        },
        [data, error, loading]
    )

    return {
        flagIsActive,
        loading,
    }
}

/**
 * Use the constate factory to lift up the useFeatureFlags functionality to React Context.
 */
const [FeatureFlagsProvider, useFeatureFlagsContext] = constate(useFeatureFlags)

export { FeatureFlagsProvider }
export default useFeatureFlagsContext

var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ffc8b6a2b34786e6b851394862979ba41aeeed4c"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init, Replay } from "@sentry/nextjs"
import { BrowserTracing } from "@sentry/tracing"

init({
    dsn: "https://8d9c4991b2f64cabb4f0d06e43f68d87@o369723.ingest.sentry.io/5799762",
    environment: process.env.NEXT_PUBLIC_ENV,
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
    denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ],
    integrations: [
        new BrowserTracing({
            tracePropagationTargets: [
                "localhost",
                /^\//,
                /pallet\.com/,
                /pallet-staging\.com/,
            ],
        }),
        new Replay({
            maskAllText: false,
            maskAllInputs: false,
        }),
    ],
})

import { gql, useMutation } from "@apollo/client"
import { FormModal, notify, Text, TextAreaField } from "@pallet-hq/hegel"

import { Formik } from "formik"
import useFeedbackModal from "state/useFeedbackModal"
import { SendFeedbackMutation } from "__generated__/SendFeedbackMutation"

const SEND_FEEDBACK = gql`
    mutation SendFeedbackMutation($feedback: String!) {
        submitFeedback(feedback: $feedback) {
            ok
        }
    }
`

const FeedbackModal = () => {
    const { isFeedbackModalVisible, setFeedbackModalVisibility } =
        useFeedbackModal()

    const [sendFeedback] = useMutation<SendFeedbackMutation>(SEND_FEEDBACK)

    return (
        <Formik
            initialValues={{ feedback: "" }}
            onSubmit={({ feedback }, { resetForm }) => {
                return sendFeedback({
                    variables: { feedback },
                })
                    .then(({ data }) => {
                        if (data?.submitFeedback?.ok)
                            notify.success(
                                "Thank you for helping improve the product!"
                            )
                        else
                            notify.fail(
                                "There was an issue submitting your feedback at this time. Please try again later."
                            )
                    })
                    .finally(() => {
                        setFeedbackModalVisibility(false)
                        resetForm()
                    })
            }}
        >
            <FormModal
                isOpen={isFeedbackModalVisible}
                hideModal={() => setFeedbackModalVisibility(false)}
                action="Submit Feedback"
                width="small"
                withSecondaryAction
                title="Send feedback"
                secondaryAction="Cancel"
                onSecondaryAction={() => setFeedbackModalVisibility(false)}
            >
                <Text variant="paragraphMedium" color="gray600">
                    How can we improve Pallet? If you have a feature request,
                    can you also share why it&apos;s important to you?
                </Text>
                <TextAreaField
                    name="feedback"
                    label=""
                    mt="medium"
                    width="100%"
                    placeholder="E.g. I'd like to connect my ATS"
                    rows={4}
                />
            </FormModal>
        </Formik>
    )
}

export default FeedbackModal

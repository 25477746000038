import styled from "@emotion/styled"
import { Button, Modal, Text, theme } from "@pallet-hq/hegel"
import LinkText from "components/Link/LinkText"
import config from "config"
import useLoginModal from "state/useLoginModal"
import useSignupPrompt from "state/useSignupPrompt"

const SignUpPrompt = () => {
    const {
        showingSignupPrompt,
        hideSignupPrompt,
        promptTitle,
        promptCaption,
    } = useSignupPrompt()
    const { showLoginModal } = useLoginModal()

    return (
        <Modal
            isOpen={showingSignupPrompt}
            hideModal={hideSignupPrompt}
            showIcon={false}
            width="small"
        >
            <SignUpPromptDiv>
                <PromptText>
                    <PromptTitle variant="headlineMedium">
                        {promptTitle}
                    </PromptTitle>
                    <PromptCaption variant="paragraphMedium">
                        {promptCaption}
                    </PromptCaption>
                </PromptText>
                <Button
                    onClick={() => {
                        showLoginModal()
                        hideSignupPrompt()
                    }}
                >
                    Sign up
                </Button>
                <LoginDiv>
                    <Text variant="paragraphMedium">
                        Already have an account?
                    </Text>
                    <LinkText
                        size="small"
                        href={`${config.CANONICAL_URL}/login`}
                    >
                        Log in
                    </LinkText>
                </LoginDiv>
            </SignUpPromptDiv>
        </Modal>
    )
}

const SignUpPromptDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-bottom: ${theme.space.medium};
`

const PromptText = styled.div`
    margin: ${theme.space.large} 0;
`

const PromptTitle = styled(Text)`
    margin-bottom: ${theme.space.small};
`

const PromptCaption = styled(Text)`
    margin: 0 ${theme.space.medium};
`

const LoginDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${theme.space.medium};
`

export default SignUpPrompt

import { gql, useLazyQuery, useMutation } from "@apollo/client"
import { EmailField } from "@pallet/components/fields/Email"
import OrDivider from "components/Modals/LoginModal/OrDivider"
import OnboardingLayout from "components/OnboardingLayout"
import SocialLogins from "components/SocialLogins"
import { Formik } from "formik"
import { useEffect } from "react"
import useLoginModal, { LoginModalStep } from "state/useLoginModal"
import * as Yup from "yup"
import { UserAccountTypeQuery } from "__generated__/UserAccountTypeQuery"
import { SubmitButton } from "./shared"
import ModalHeading from "./ModalHeading"

const USER_ACCOUNT_TYPE_QUERY = gql`
    query UserAccountTypeQuery($email: String) {
        user(email: $email) {
            isPasswordless
        }
    }
`

const CONFIRM_PASSWORDLESS_EMAIL = gql`
    mutation ConfirmPasswordlessEmailMutation($email: String!) {
        confirmPasswordlessEmail(email: $email) {
            ok
        }
    }
`

const getNextStep = (data: UserAccountTypeQuery) => {
    let step = LoginModalStep.SIGNUP

    if (data?.user) {
        if (data.user.isPasswordless) step = LoginModalStep.PASSWORDLESS
        else step = LoginModalStep.LOGIN
    }

    return step
}

const EmailForm = () => {
    const { setActiveStep, username, setUsername } = useLoginModal()
    const [getAccountType, { loading, data }] =
        useLazyQuery<UserAccountTypeQuery>(USER_ACCOUNT_TYPE_QUERY, {
            fetchPolicy: "no-cache",
        })
    const [sendPasswordlessEmail] = useMutation(CONFIRM_PASSWORDLESS_EMAIL)

    const handleSubmit = ({ email }: { email: string }) => {
        setUsername(email)
        getAccountType({ variables: { email } })
    }

    useEffect(() => {
        if (data) {
            const nextStep = getNextStep(data)
            setActiveStep(nextStep)

            if (nextStep === LoginModalStep.PASSWORDLESS) {
                sendPasswordlessEmail({ variables: { email: username } })
            }
        }
    }, [data, setActiveStep, username, sendPasswordlessEmail])

    return (
        <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{ email: username }}
            validationSchema={Yup.object().shape({
                ...EmailField.validators.required,
            })}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <EmailField
                        autoComplete="email"
                        sizePreset="large"
                        width="100%"
                        autoFocus
                    />
                    <SubmitButton loading={isSubmitting || loading}>
                        Continue
                    </SubmitButton>
                </form>
            )}
        </Formik>
    )
}

const EmailStep = () => (
    <OnboardingLayout
        heading="With your Pallet account, "
        subheading="you can share your profile, apply for jobs, join new collectives, and so much more."
    >
        <ModalHeading text="Sign up or log in" />
        <EmailForm />
        <OrDivider />
        <SocialLogins />
    </OnboardingLayout>
)

export default EmailStep

import { Button, ButtonProps } from "@pallet-hq/hegel"

export const SubmitButton = (props: ButtonProps) => (
    <Button
        type="submit"
        size="large"
        width="100%"
        mt="xlarge"
        mb="32px"
        {...props}
    />
)

import { useRouter } from "next/router"
import queryString from "query-string"
import { ParsedUrlQuery, ParsedUrlQueryInput } from "querystring"
import { UrlObject } from "url"

export type Url = UrlObject | string

export const buildPalletUrl = (url: Url, query: ParsedUrlQuery) => {
    const { pallet } = query

    if (typeof url === "string") {
        return queryString.stringifyUrl({
            url,
            query: { pallet },
        })
    } else {
        const query = url.query as ParsedUrlQueryInput
        return {
            ...url,
            query: {
                ...query,
                pallet,
            },
        }
    }
}

export const usePalletRouter = () => {
    const router = useRouter()

    return {
        ...router,
        push: (url: Url, ...params: any) => {
            return router.push(buildPalletUrl(url, router.query), ...params)
        },
        replace: (url: Url, ...params: any) => {
            return router.replace(buildPalletUrl(url, router.query), ...params)
        },
        prefetch: (url: string, ...params: any) => {
            return router.prefetch(
                buildPalletUrl(url, router.query) as string,
                ...params
            )
        },
        createLink: (url: Url) => buildPalletUrl(url, router.query),
    }
}

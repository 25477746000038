import { ApolloClient, gql } from "@apollo/client"
import { FeaturesQuery } from "__generated__/FeaturesQuery"

/**
 * Query used to get all feature flags and their active status.
 * Used in the `checkFlagIsActive` helper and the `useFeatureFlags` hook.
 */
export const FEATURES_QUERY = gql`
    query FeaturesQuery {
        features {
            id
            name
            isActive
        }
    }
`

/**
 * Checks whether a provided feature flag is active or not. Useful in SSR
 * queries and anytime where you can't use the `useFeatureFlags` hook.
 * @param apolloClient - current Apollo Client instance
 * @param featureName - name of the feature flag
 * @returns A promise resolving to `true` if flag is active, `false` otherwise
 */
export const checkFlagIsActive = async (
    apolloClient: ApolloClient<any>,
    featureName: string
): Promise<boolean> => {
    const { data } = await apolloClient.query<FeaturesQuery>({
        query: FEATURES_QUERY,
    })
    const matchingFeature = data?.features?.find(
        feature => feature?.name === featureName
    )
    return !!matchingFeature?.isActive
}

import { FieldPolicy } from "@apollo/client"
import { PaginatedDataFieldPolicy } from "./paginatedDataField"

/**
 * Field Policy for paginated candidates with filters
 */
export const FilteredCandidatesFieldPolicy = (): FieldPolicy => {
    return PaginatedDataFieldPolicy((args: any) => {
        const filters = [
            "experiences",
            "jobTypeCategories",
            "workTypes",
            "applicationStatus",
            "locationPreferences",
            "hasWorkAuthorizationUsa",
            "keywords",
        ]
        // make a new cache object if pagination is not used
        return !args?.first ? [...filters, "after"] : filters
    })
}

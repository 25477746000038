import styled from "@emotion/styled"
import { Box, Divider, Text, theme } from "@pallet-hq/hegel"
import { FlexColumn } from "components/atoms"
import config from "config"
import { ReactNode } from "react"
import { FiInbox, FiInfo, FiMessageCircle } from "react-icons/fi"
import { useIntercom } from "react-use-intercom"
import useAuthContext from "state/useAuthContext"
import useFeatureFlagsContext from "state/useFeatureFlags"
import useFeedbackModal from "state/useFeedbackModal"

const HelpItem = ({
    icon,
    indicator,
    title,
    description,
    onClick,
}: {
    icon: ReactNode
    indicator?: boolean
    title: string
    description?: string
    onClick: (a: any) => void
}) => {
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={onClick}
            py={description ? theme.space.xxsmall : theme.space.xsmall}
            px={theme.space.xsmall}
        >
            <Box
                style={{ gap: theme.space.medium }}
                display="flex"
                alignItems="center"
            >
                {icon}
                <FlexColumn>
                    <Text variant="uiSmall400">{title}</Text>
                    {description && (
                        <Text variant="uiTiny400" color="gray600">
                            {description}
                        </Text>
                    )}
                </FlexColumn>
            </Box>
            {indicator && <Indicator />}
        </Box>
    )
}

const HelpMenuContent = ({ closeMenu }: { closeMenu: () => void }) => {
    const { setFeedbackModalVisibility } = useFeedbackModal()
    const { flagIsActive } = useFeatureFlagsContext()
    const { authed } = useAuthContext()
    const intercom = useIntercom()

    return (
        <ContentContainer>
            <Content>
                {authed && flagIsActive("INTERCOM_CHAT") && (
                    <HelpItem
                        icon={<FiMessageCircle size={16} />}
                        title="Live chat"
                        description="Have a question?"
                        indicator
                        onClick={() => {
                            intercom.show()
                        }}
                    />
                )}
                <HelpItem
                    icon={<FiInfo size={16} />}
                    title="Help center"
                    onClick={() => {
                        window.open(config.SUPPORT_LINK, "_blank")
                        closeMenu()
                    }}
                />
            </Content>
            <Divider />
            <Content>
                <HelpItem
                    icon={<FiInbox size={16} />}
                    title="Send feedback"
                    onClick={() => {
                        setFeedbackModalVisibility(true)
                        closeMenu()
                    }}
                />
            </Content>
        </ContentContainer>
    )
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.space.xxsmall};
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${theme.space.xsmall};
    border-radius: ${theme.space.xsmall};
    border: 1px solid ${theme.colors.gray200};
    box-shadow: 0px 30px 12px rgba(0, 0, 0, 0.01),
        0px 17px 10px rgba(0, 0, 0, 0.03), 0px 7px 7px rgba(0, 0, 0, 0.05),
        0px 2px 4px rgba(0, 0, 0, 0.06), 0px 0px 0px rgba(0, 0, 0, 0.06);
    width: 224px;
    gap: ${theme.space.xsmall};
    background: ${theme.colors.white};
`

const Indicator = styled.div`
    width: 8px;
    height: 8px;

    background: ${theme.colors.green600};
    border-radius: 50%;

    flex: none;
    order: 1;
    flex-grow: 0;
`

export default HelpMenuContent

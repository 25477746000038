import { FieldPolicy } from "@apollo/client"
import { PaginatedDataFieldPolicy } from "./paginatedDataField"

/**
 * Field Policy for paginated searchRequest groups
 */
export const SearchRequestGroupsFieldPolicy = (): FieldPolicy => {
    return PaginatedDataFieldPolicy((args: any) => {
        const filters = ["onlySelected"]
        // make a new cache object if pagination is not used
        return !args?.first ? [...filters, "after"] : filters
    })
}

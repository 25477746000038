import { gql } from "@apollo/client"
import { CandidateSectionFragment } from "features/candidate/profile/display/GridProfileView/fragments"
import { WorkStatusSectionFragment } from "features/candidate/profile/display/WorkStatusSection/fragment"

export const MemberFragment = gql`
    fragment Member_candidate on CandidateType {
        id
        uuid
        applicationStatusEnum
        hasViewed
        updatedAt
        user {
            id
            headline
            ...WorkStatusSection_user
        }
        ...CandidateSection_candidate
    }
    ${CandidateSectionFragment}
    ${WorkStatusSectionFragment}
`

export const PENDING_CANDIDATES_FRAGMENT = gql`
    fragment PendingCandidates_group on GroupType {
        id
        pendingCandidates: candidates(
            first: $offset
            after: $cursor
            applicationStatus: $pendingCandidateStatus
        ) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    ...Member_candidate
                }
            }
        }
    }
    ${MemberFragment}
`

export const ACTIVE_CANDIDATES_FRAGMENT = gql`
    fragment ActiveCandidates_group on GroupType {
        id
        activeCandidates: candidates(
            first: $offset
            after: $cursor
            applicationStatus: $activeCandidateStatus
        ) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    ...Member_candidate
                }
            }
        }
    }
    ${MemberFragment}
`

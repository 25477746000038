import config from "config"
import Head from "next/head"
import { useRouter } from "next/router"

const DefaultHead = () => {
    const { asPath } = useRouter()

    return (
        <Head>
            <meta charSet="utf-8" />
            <link rel="shortcut icon" href="/favicon.ico" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
            />
            <meta name="theme-color" content="#fff" />
            <link rel="apple-touch-icon" href="/logo192.png" />

            <meta
                key="og:title"
                property="og:title"
                content="Curate your job search | Pallet"
            />
            <meta
                key="og:description"
                property="og:description"
                content="Pallet brings curation to your job search, so you see the jobs that count."
            />
            <meta
                key="og:image"
                property="og:image"
                content="https://cardea.imgix.net/media/logos/pallet-logomark-16x9.png"
            />
            <meta
                property="og:url"
                content={`${config.CANONICAL_URL}${asPath}`}
            />
            <meta property="og:type" content="website" />
            <meta property="fb:app_id" content="336267880205695" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@Pallet_HQ" />
            <meta name="robots" content="noindex, nofollow" />
            <link rel="manifest" href="/manifest.json" />
            <title key="title">Pallet</title>
        </Head>
    )
}

export default DefaultHead

import { RefObject, useEffect, useRef, useState } from "react"

export type StickyOptions = {
    stickyRef?: RefObject<HTMLDivElement>
    isSticky: boolean
}

export const useSticky = (offset?: number): StickyOptions => {
    const stickyRef = useRef<HTMLDivElement>(null)
    const [isSticky, setSticky] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if (stickyRef.current) {
                setSticky(
                    stickyRef.current.getBoundingClientRect().top <=
                        (offset || 0)
                )
            }
        }

        // call once on page load
        handleScroll()
        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", () => handleScroll)
        }
    }, [offset, stickyRef])

    return { stickyRef, isSticky }
}

/*
 * Wrapper around the Hegel LinkText component to provide "smart" linking
 */

import { LinkText as HegelLinkText, LinkTextProps } from "@pallet-hq/hegel"
import SmartLink from "./SmartLink"

const LinkText = (props: LinkTextProps) =>
    props.href ? (
        <SmartLink href={props.href}>
            <HegelLinkText {...props} />
        </SmartLink>
    ) : (
        <HegelLinkText {...props} />
    )

export default LinkText

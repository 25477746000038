import { useState } from "react"
import constate from "constate"

const [SignupPromptProvider, useSignupPrompt] = constate(() => {
    const [showingSignupPrompt, setShowingModal] = useState(false)
    const [promptTitle, setPromptTitle] = useState("")
    const [promptCaption, setPromptCaption] = useState("")

    const showSignupPrompt = (title: string, caption: string) => {
        setPromptTitle(title)
        setPromptCaption(caption)
        setShowingModal(true)
    }

    const hideSignupPrompt = () => setShowingModal(false)

    return {
        showingSignupPrompt,
        showSignupPrompt,
        hideSignupPrompt,
        promptTitle,
        promptCaption,
    }
})

export { SignupPromptProvider }

export default useSignupPrompt

import { TypePolicy } from "@apollo/client"
import { relayStylePagination } from "@apollo/client/utilities"
import { FilteredCandidatesFieldPolicy } from "apollo/fieldPolicies/filteredCandidatesField"
import { LikedByCandidatesFieldPolicy } from "apollo/fieldPolicies/likedByCandidatesField"
import { PaginatedDataFieldPolicy } from "apollo/fieldPolicies/paginatedDataField"
import { SearchRequestGroupsFieldPolicy } from "apollo/fieldPolicies/searchRequestGroupsField"
import { MatchingCandidatesFieldPolicy } from "apollo/fieldPolicies/matchingCandidatesField"

export const ApplylistType = (): TypePolicy => ({
    fields: {
        companySet: PaginatedDataFieldPolicy(),
        partneredTeams: relayStylePagination(),
        posts: PaginatedDataFieldPolicy([
            "selectedCategory",
            "experiences",
            "locations2",
            "isOpenToRemote",
            "jobTypes",
            "ignoreJobTypes",
        ]),
        listingsToReview: PaginatedDataFieldPolicy(),
        subscriptions: PaginatedDataFieldPolicy(["approvalStatus"]),
    },
})

export const GroupType = (): TypePolicy => ({
    fields: {
        upcomingCandidates: PaginatedDataFieldPolicy(),
        dropCandidates: relayStylePagination(["filters"]),
        candidates: FilteredCandidatesFieldPolicy(),
        interactions: PaginatedDataFieldPolicy(["interaction"]),
        introRequests: relayStylePagination(["status"]),
    },
})

export const SearchRequestType = (): TypePolicy => ({
    fields: {
        introRequests: PaginatedDataFieldPolicy(["status", "statuses"]),
        candidates: FilteredCandidatesFieldPolicy(),
        matchingCandidates: MatchingCandidatesFieldPolicy(),
        interactions: PaginatedDataFieldPolicy(["interaction", "createdBy"]),
        approvedSearchableGroups: SearchRequestGroupsFieldPolicy(),
    },
})

export const BaseUserInterface = (): TypePolicy => ({
    fields: {
        candidates: relayStylePagination(["applicationStatus"]),
        introRequests: PaginatedDataFieldPolicy(),
        likableTeams: relayStylePagination(["pallets"]),
        workExperiences: PaginatedDataFieldPolicy(),
    },
})

export const BaseUserType = (): TypePolicy => ({
    fields: {
        candidates: relayStylePagination(["applicationStatus"]),
        introRequests: PaginatedDataFieldPolicy(),
        likableTeams: relayStylePagination(["pallets"]),
        workExperiences: PaginatedDataFieldPolicy(),
    },
})

export const RecruitingTeamType = (): TypePolicy => ({
    fields: {
        agreements: relayStylePagination(["filters"]),
        invitations: PaginatedDataFieldPolicy(),
        members: PaginatedDataFieldPolicy(),
        posts: PaginatedDataFieldPolicy(),
        recommendedGroups: relayStylePagination(["filters"]),
        likedByCandidates: LikedByCandidatesFieldPolicy(),
        likedByIntros: relayStylePagination(["status"]),
    },
})

export const PalletAnalyticsType = (): TypePolicy => ({
    fields: {
        intros: relayStylePagination([
            "source",
            "orderBy",
            "introStatus",
            "conversationStatus",
        ]),
    },
})

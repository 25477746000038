import { getApolloInstance } from "apollo/client"
import config from "config"
import { DEVELOPMENT_URLS, PRODUCTION_URLS } from "constants/routes"
import * as http from "http"
import { NextRouter } from "next/router"
import queryString from "query-string"
import { mapValues } from "lodash"
import { PALLET_HOST_QUERY } from "queries"

const toBool = (val: any) => {
    if (typeof val === "string") {
        if (val.toLowerCase() === "true") return true
        else if (val.toLowerCase() === "false") return false
    }
    return val
}

export const parseBooleans = (query: {} = {}) => {
    return Object.fromEntries(
        Object.entries(query).map(([key, value]) => [key, toBool(value)])
    )
}

export interface PalletContext {
    host: string
    pallet: string | null
    isDevDomain: boolean
    isCustomDomain: boolean
    usingFallback: boolean
    queryString: string
    href: string
}

/*
 * Given a host and URL, synchronously return the slug of the Pallet we're on.
 * This returns null for custom domains, as the utility would have to be synchronous to make
 * the network request to obtain the slug. Future work includes using this in getPalletContext.
 */
export const getPalletSlug = (host: string, url: string) => {
    host = host || ""
    let parsedUrl = new URL(url, `http://${host}`)

    let isDevDomain = DEVELOPMENT_URLS.some(url => host.includes(url))
    let isPalletDomain = PRODUCTION_URLS.some(url => host.includes(url))
    let isCustomDomain = !isPalletDomain && !isDevDomain

    let searchParams = parsedUrl.searchParams

    let pallet = null
    if (isCustomDomain) {
        return pallet
    } else if (isPalletDomain || isDevDomain) {
        let hasSubdomain = host.split(".").length === 3
        if (hasSubdomain) {
            let subdomain = host.split(".")[0]
            let isBlacklistedSubdomain =
                subdomain === "www" || config.IS_PREVIEW || subdomain === "app"
            if (isBlacklistedSubdomain) {
                pallet = searchParams.get("pallet")
            } else {
                pallet = subdomain
            }
        } else {
            pallet = searchParams.get("pallet")
        }
    }
    return pallet
}

/*
 * Get pallet to display from a given http request.
 */
export const getPalletContext = async (
    request: http.IncomingMessage
): Promise<PalletContext> => {
    let host = request.headers.host || ""
    let parsedUrl = new URL(request.url!, `http://${host}`)

    let isDevDomain = DEVELOPMENT_URLS.some(url => host.includes(url))
    let isPalletDomain = PRODUCTION_URLS.some(url => host.includes(url))
    let isCustomDomain = !isPalletDomain && !isDevDomain

    let searchParams = parsedUrl.searchParams
    let usingFallback = false

    /* Get pallet namespace from request. In one of the following ways:
     *
     * 1) Via query if on a custom domain.
     * 2) Via parsing subdomain if on pallet or development domain.
     * 3) Via `pallet` search param as a last fallback.
     * */
    let pallet = null
    if (isCustomDomain) {
        const apolloClient = getApolloInstance({ request })
        let { data } = await apolloClient.query({
            query: PALLET_HOST_QUERY,
            variables: {
                host: host,
            },
        })
        pallet = data.pallet
    } else if (isPalletDomain || isDevDomain) {
        let hasSubdomain = host.split(".").length === 3
        if (hasSubdomain) {
            let subdomain = host.split(".")[0]
            let isBlacklistedSubdomain =
                subdomain === "www" || config.IS_PREVIEW || subdomain === "app"
            if (isBlacklistedSubdomain) {
                pallet = searchParams.get("pallet")
                usingFallback = searchParams.has("pallet")
            } else {
                pallet = subdomain
            }
        } else {
            pallet = searchParams.get("pallet")
            usingFallback = searchParams.has("pallet")
        }
    } else {
        throw new Error(`Cannot handle the unexpected host: ${host}`)
    }

    return {
        host: host,
        pallet: pallet,
        isDevDomain: isDevDomain,
        isCustomDomain: isCustomDomain,
        usingFallback: usingFallback,
        queryString: searchParams.toString(),
        href: parsedUrl.href,
    }
}

/*
 * Build new route URL.
 *
 */
export const buildNewRouteUrl = (
    palletContext: PalletContext,
    slug: string,
    newPath: string
) => {
    let searchParams = new URLSearchParams(palletContext.queryString)
    let protocol = palletContext.isDevDomain ? "http" : "https"
    let subdomain = palletContext.pallet === null ? slug : ""
    let hasSubdomain =
        subdomain !== "" && !config.REQUIRES_FALLBACK_FOR_SUBDOMAINS
    if (config.REQUIRES_FALLBACK_FOR_SUBDOMAINS) {
        searchParams.set("pallet", slug)
    }
    let queryString = searchParams.toString()
    return (
        `${protocol}://` +
        (hasSubdomain ? `${subdomain}.` : "") +
        palletContext.host.replace(/^(app\.)/, "") +
        newPath +
        (queryString ? `?${queryString}` : "")
    )
}

/*
 * Return the current url with additional query parameters.
 */
export const appendQueryParams = (
    url: string,
    queryParams: { [key: string]: string }
) => {
    let parsedUrl = new URL(url)
    Object.entries(queryParams).forEach(([key, value]) => {
        parsedUrl.searchParams.set(key, value)
    })
    return parsedUrl.toString()
}

/*
 * Navigate to path while preserving existing URL parameters
 */
export const navigateWithExistingParams = (
    router: NextRouter,
    path: string
) => {
    const hasParams = !!Object.keys(router.query).length
    const separator = hasParams ? "?" : ""
    return router.push(
        `${path}${separator}${queryString.stringify(router.query)}`
    )
}

/*
 * Builds a redirect object used in getServerSideProps
 * Automatically appends `?pallet={pallet_name}` if necessary
 * Usage:
 *   - buildSSRRedirect(palletContext, "/pathname?foo=bar")
 *   - buildSSRRedirect(palletContext, {
 *        url: "/path",
 *        query: {
 *          foo: "bar"
 *        }
 *     })
 */
export const buildSSRRedirect = (
    palletContext: PalletContext,
    destination: queryString.UrlObject | string,
    permanent: boolean = false
) => {
    const { usingFallback, pallet } = palletContext
    const destinationString =
        typeof destination === "string"
            ? queryString.stringifyUrl({
                  url: destination,
                  query: {
                      pallet: usingFallback ? pallet : undefined,
                  },
              })
            : queryString.stringifyUrl({
                  ...destination,
                  query: {
                      ...destination.query,
                      pallet: usingFallback ? pallet : undefined,
                  },
              })

    return {
        redirect: {
            permanent,
            destination: destinationString,
        },
    }
}

/**
 * Construct a URL. It can handle creating relative URLs, appending query
 * params, or both.
 */
const _makeURL = ({
    base,
    path = "",
    params,
    appendParams = true,
}: {
    base: string
    path?: string
    params?: { [key: string]: string }
    appendParams?: boolean
}) => {
    let url = new URL(path, base)
    if (!appendParams) {
        url.searchParams.forEach((_, param) => {
            url.searchParams.delete(param)
        })
    }
    if (params) {
        Object.entries(params).forEach(([param, value]) => {
            url.searchParams.set(param, value)
        })
    }
    return url
}

/**
 * Construct a URL. On the client-side it uses window as the base, on the server
 * side, it relies on the pallet context.
 */
export const makeURL = (options?: {
    path?: string
    params?: { [key: string]: string | boolean | number }
    palletContext?: PalletContext
    ignoreFallbackParam?: boolean
}) => {
    if (typeof window === "undefined" && !options?.palletContext) {
        throw new Error(
            "The pallet context is required when not running in browser."
        )
    }
    let base = options?.palletContext?.href ?? window.location.href
    let path = options?.path ?? ""
    let params = mapValues(options?.params, String) ?? {}
    if (!options?.ignoreFallbackParam) {
        if (options?.palletContext?.usingFallback) {
            params["pallet"] = options.palletContext.pallet!
        } else {
            let _param = new URLSearchParams(window.location.search).get(
                "pallet"
            )
            if (_param) {
                params["pallet"] = _param
            }
        }
    }
    let appendParams = true
    return _makeURL({
        base: base,
        path: path,
        params: params,
        appendParams: appendParams,
    }).toString()
}

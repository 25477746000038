import { gql } from "@apollo/client"
import { TalentPreferencesViewFragment } from "@pallet/features/onboardingL1/TalentPreferencesView/fragments"
import { TalentWorkDetailsViewFragment } from "@pallet/features/onboardingL1/TalentWorkDetailsView/fragments"
import { ManageLayoutMenuFragment } from "page-components/pallet/ManageLayout/ManageMenu/fragment"
import {
    ACTIVE_SUBSCRIPTIONS_FRAGMENT,
    PENDING_SUBSCRIPTIONS_FRAGMENT,
} from "page-components/talent/manage/Company/fragments"
import {
    ACTIVE_CANDIDATES_FRAGMENT,
    PENDING_CANDIDATES_FRAGMENT,
} from "page-components/talent/manage/Member/fragments"

export const TAGS_QUERY = gql`
    query TagsQuery {
        tags {
            edges {
                node {
                    id
                    title
                }
            }
        }
    }
`

export const EXPORTS_QUERY = gql`
    query ExportsQuery {
        exports {
            edges {
                node {
                    id
                    createdAt
                    status
                    url
                }
            }
        }
    }
`

export const EMAIL_CAMPAIGNS_QUERY = gql`
    query EmailCampaignsQuery {
        emailCampaigns {
            edges {
                node {
                    id
                    title
                }
            }
        }
    }
`

export const WORKSPACE_QUERY = gql`
    query WorkspaceQuery {
        workspace {
            id
            jobPosts {
                id
                createdAt
                title
                company {
                    id
                    name
                }
            }
        }
    }
`

export const INBOUND_LAYOUT_QUERY = gql`
    query InboundLayoutQuery($team: String!) {
        recruitingTeam(uuid: $team) {
            id
            inboundFilterablePosts {
                id
                uuid
                jobTitle
            }
        }
    }
`

export const TEAM_INBOUND_QUERY = gql`
    query TeamInboundQuery($team: String!) {
        recruitingTeam(uuid: $team) {
            id
            searchKey
        }
    }
`

export const PALLET_SEARCH_KEY_QUERY = gql`
    query PalletSearchKeyQuery($slug: String!) {
        protectedPallet(slug: $slug) {
            ... on ApplylistType {
                id
                typesenseSearchKey
            }
        }
    }
`

export const SUPERUSER_SEARCH_KEY_QUERY = gql`
    query SuperuserSearchKeyQuery {
        superuserSearchKey
    }
`

export const MANAGE_LAYOUT_QUERY = gql`
    query ManageLayoutQuery($slug: String!) {
        protectedPallet(slug: $slug) {
            ... on ApplylistType {
                id
                ...ManageLayoutMenu_applylist
            }
            ... on ObjectNotFoundErrorType {
                message
            }
            ... on PermissionErrorType {
                message
            }
        }
    }
    ${ManageLayoutMenuFragment}
`

export const COLLECTIVES_APPLICATIONS_QUERY = gql`
    query CollectivesApplicationsQuery(
        $slug: String!
        $cursor: String
        $offset: Int!
        $pendingCandidateStatus: String!
    ) {
        protectedPallet(slug: $slug) {
            ... on ApplylistType {
                id
                slug
                group {
                    id
                    ...PendingCandidates_group
                }
            }
        }
    }
    ${PENDING_CANDIDATES_FRAGMENT}
`

export const ACTIVE_COLLECTIVE_MEMBERS_QUERY = gql`
    query ActiveCollectiveMembersQuery(
        $slug: String!
        $cursor: String
        $offset: Int!
        $activeCandidateStatus: String!
    ) {
        protectedPallet(slug: $slug) {
            ... on ApplylistType {
                id
                slug
                group {
                    id
                    ...ActiveCandidates_group
                }
            }
        }
    }
    ${ACTIVE_CANDIDATES_FRAGMENT}
`

export const ACTIVE_COMPANIES_QUERY = gql`
    query ActiveCompaniesQuery(
        $slug: String!
        $cursor: String
        $offset: Int!
        $activeSubscriptionStatus: CompanySubscriptionStatusEnum!
    ) {
        protectedPallet(slug: $slug) {
            ... on ApplylistType {
                id
                slug
                ...ActiveSubscriptions_applylist
            }
        }
    }
    ${ACTIVE_SUBSCRIPTIONS_FRAGMENT}
`

export const COMPANY_APPLICATIONS_QUERY = gql`
    query CompanyApplicationsQuery(
        $slug: String!
        $cursor: String
        $offset: Int!
        $pendingSubscriptionStatus: CompanySubscriptionStatusEnum!
    ) {
        protectedPallet(slug: $slug) {
            ... on ApplylistType {
                id
                slug
                ...PendingSubscriptions_applylist
            }
        }
    }
    ${PENDING_SUBSCRIPTIONS_FRAGMENT}
`

export const SUBLOCATIONS_QUERY = gql`
    query SublocationsQuery($searchTerm: String!, $first: Int!) {
        sublocations3(name: $searchTerm, first: $first) {
            edges {
                node {
                    id
                    fullName
                    shortName
                    slug
                }
            }
        }
    }
`

export const WORK_TYPES_QUERY = gql`
    query WorkTypesQuery {
        workTypes {
            id
            name
            slug
        }
    }
`

export const INDUSTRIES_QUERY = gql`
    query IndustriesQuery {
        industries {
            id
            slug
            name
        }
    }
`

/*
 * Get pallet slug from host
 */
export const PALLET_HOST_QUERY = gql`
    query PalletQuery($host: String!) {
        pallet(host: $host)
    }
`

export const PREFERENCES_FORM_QUERY = gql`
    query PreferencesFormQuery {
        me {
            ...TalentPreferencesView_me
        }
    }
    ${TalentPreferencesViewFragment}
`

export const WORK_DETAILS_FORM_QUERY = gql`
    query WorkDetailsFormQuery {
        me {
            ...TalentWorkDetailsView_me
        }
    }
    ${TalentWorkDetailsViewFragment}
`

export const GENERAL_ONBOARD_SIDEBAR_QUERY = gql`
    query GeneralOnboardSidebarQuery($slug: String!) {
        applylist(slug: $slug) {
            id
            defaultName
            logo {
                source
            }
            curatorAvatar {
                source
            }
            curatorName
            subheading
            group {
                id
                description
            }
        }
    }
`

export const JOB_DETAILS_SIDEBAR_QUERY = gql`
    query JobDetailsSidebarQuery($slug: String!, $uuid: UUID!) {
        applylist(slug: $slug) {
            id
            defaultName
            logo {
                source
            }
            curatorAvatar {
                source
            }
            curatorName
            subheading
            group {
                id
                description
            }
        }
        post(uuid: $uuid) {
            id
            uuid
            jobTitle
            shortDescription
            salary
            company {
                id
                name
                image
            }
            experience {
                shortName
            }
            locations {
                shortLabel
            }
            owner {
                uuid
            }
        }
    }
`

export const APPLICATION_CONFIRMED_QUERY = gql`
    query ApplicationConfirmedQuery($uuid: UUID!) {
        post(uuid: $uuid) {
            id
            company {
                id
                name
            }
        }
    }
`

export const SEGMENTS_QUERY = gql`
    query SegmentsQuery {
        segments {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

export const SEGMENT_QUERY = gql`
    query SegmentQuery($id: String!) {
        segment(id: $id) {
            ... on SegmentType {
                id
                name
            }
            ... on ErrorInterface {
                message
            }
        }
    }
`

export const TEAM_INTEGRATION_QUERY = gql`
    query TeamIntegrationQuery($team: String!) {
        recruitingTeam(uuid: $team) {
            id
            integration {
                id
                name
                isActive
                logo
                color
                status
                source
                candidateTags
            }
        }
    }
`

export const TEAM_INTEGRATION_JOBS_QUERY = gql`
    query TeamIntegrationJobsQuery($team: String!) {
        recruitingTeam(uuid: $team) {
            id
            integration {
                id
                isActive
                name
                jobs {
                    id
                    name
                    modifiedAt
                    stages {
                        id
                        name
                        order
                    }
                }
            }
        }
    }
`

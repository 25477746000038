import { gql, useMutation, useQuery } from "@apollo/client"
import { setUser } from "@sentry/nextjs"
import { getApolloInstance } from "apollo/client"
import { TRACK_EVENTS } from "constants/events"
import constate from "constate"
import mixpanel from "mixpanel-browser"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { identify } from "react-fullstory"
import { useIntercom } from "react-use-intercom"
import { DataAttributesCompany } from "react-use-intercom/src/types"
import { AuthQuery } from "__generated__/AuthQuery"

const AUTH_QUERY = gql`
    query AuthQuery {
        me {
            id
            slug
            firstName
            lastName
            profileImageUrl
            email
            isStaff
            curatedPallets {
                totalCount
            }
            introRequests {
                totalCount
            }
            linkedinUrl
            websiteUrl
            bio
            recentEmployer {
                id
                slug
                name
            }
            currentJobType {
                id
                name
                slug
                experienceName
            }
            currentTitle
            isUnemployed
            isLaidOff
            hasNoWorkExperience
            yearsOfExperience
            currentJobStartDate
            currentJobEndDate
            featureHasSeenAutoReview
            currentTeam {
                id
                uuid
                company {
                    id
                    slug
                    name
                    image
                }
            }
        }
    }
`

const LOGOUT_MUTATION = gql`
    mutation LogoutMutation {
        logout {
            user {
                id
            }
        }
    }
`

const [AuthContextProvider, useAuthContext] = constate(() => {
    const router = useRouter()
    const { data, loading, refetch } = useQuery<AuthQuery>(AUTH_QUERY, {
        ssr: false,
    })
    const user = data?.me ?? null
    const authed = !!data?.me
    const isStaff = !!data?.me?.isStaff

    const refresh = refetch

    const client = getApolloInstance()
    const [logoutMutation] = useMutation(LOGOUT_MUTATION)
    const intercom = useIntercom()

    const logout = async () => {
        if (loading || !authed) return
        mixpanel.track(TRACK_EVENTS.USER_LOGGED_OUT)
        await logoutMutation()
        await refresh()
        identify(false)
        setUser(null)
        mixpanel.reset()
        await client.cache.reset()
        // This should direct the user to the /jobs page if theyre on
        // a Pallet, or login page if on the root app domain
        router.push("/")
    }

    useEffect(() => {
        if (authed && data?.me?.email) {
            const USER_ID = data.me.email
            mixpanel.identify(USER_ID)
            identify(USER_ID, {
                email: data.me.email,
                displayName: `${data.me.firstName} ${data.me.lastName}`,
            })
            mixpanel.people.set({
                $email: data.me.email,
                USER_ID,
                name: `${data.me.firstName} ${data.me.lastName}`,
                firstName: data.me.firstName,
                lastName: data.me.lastName,
            })
            mixpanel.register({
                authenticated: authed,
            })
            setUser({ email: data.me.email })

            let companyData: DataAttributesCompany | undefined
            const isCurator = data.me.curatedPallets.totalCount > 0
            if (!isCurator) {
                const company = data.me.currentTeam?.company
                if (company) {
                    companyData = {
                        companyId: company.slug,
                        name: company.name,
                    }
                }
            }
            intercom.boot({
                email: data.me.email,
                name: `${data.me.firstName} ${data.me.lastName}`,
                userId: data.me.id,
                company: companyData,
                customAttributes: {
                    is_curator: isCurator,
                },
            })
        } else if (!authed) {
            intercom.shutdown()
        }
    }, [authed, data, intercom])

    return {
        user,
        authed,
        loading,
        refresh,
        logout,
        isStaff,
    }
})

export { AuthContextProvider }

export default useAuthContext

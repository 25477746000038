import {
    ApolloClient,
    ApolloLink,
    createHttpLink,
    InMemoryCache,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { RetryLink } from "@apollo/client/link/retry"
import { createUploadLink } from "apollo-upload-client"
import config from "config"
import merge from "deepmerge"
import * as http from "http"
import { isEqual } from "lodash"
import { useMemo } from "react"
import { getPalletSlug } from "utils/router"
import { hasWindow } from "utils/window"
import {
    ApplylistType,
    BaseUserInterface,
    BaseUserType,
    GroupType,
    PalletAnalyticsType,
    RecruitingTeamType,
    SearchRequestType,
} from "./typePolicies"

let apolloClient: ApolloClient<any>

const timeStartLink = new ApolloLink((operation, forward) => {
    operation.setContext({ start: Date.now() })
    return forward(operation)
})

const timeEndLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(data => {
        const time = Date.now() - operation.getContext().start
        console.debug(
            `DEBUG :: operation ${operation.operationName} took ${time}ms to complete`
        )
        return data
    })
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        )
    }
    if (networkError) {
        console.log(`[Network error]: ${networkError}`)
    }
})

const retryLink = new RetryLink({
    delay: {
        initial: 100, // exponential backoff: 100ms, 200, 400, 800...
        max: Infinity,
        jitter: true, // random delay between initial and 2x initial
    },
    attempts: {
        max: 3,
        retryIf: (error, _operation) => {
            const retry = !!error && error.statusCode >= 500
            if (retry) {
                console.error(
                    `Retrying: Operation ${_operation.operationName} due to error ${error} with code ${error.statusCode}`
                )
            }
            return retry
        },
    },
})

/*
 * PalletContextLink returns an apollo link that appends context of the current pallet
 * as a header in the Apollo Request. Custom domains will be handled in the backend
 * through middleware.
 */

const palletContextLink = (request?: http.IncomingMessage) => {
    let pallet: string | null = null
    if (hasWindow()) {
        pallet = getPalletSlug(
            window.location.host,
            window.location.pathname + window.location.search
        )
    } else if (request) {
        pallet = getPalletSlug(request.headers.host || "", request.url!)
    }
    return new ApolloLink((operation, forward) => {
        operation.setContext(({ headers }: any) => ({
            headers: {
                pallet,
                ...headers,
            },
        }))
        return forward(operation)
    })
}

const createIsomorphicLink = (request?: http.IncomingMessage) => {
    const uploadLink = createUploadLink({
        uri: "/api/v1/graphql",
        credentials: "include",
    }) as unknown as ApolloLink
    const httpLink = !hasWindow()
        ? createHttpLink({
              uri: `${config.SERVER_URL}/graphql`,
              credentials: "include",
              headers: {
                  cookie: request?.headers.cookie,
              },
          })
        : null
    return ApolloLink.from([
        palletContextLink(request),
        retryLink,
        errorLink,
        ...(!hasWindow() && config.DEBUG_SSR
            ? [timeStartLink, timeEndLink]
            : []),
        ...(hasWindow() ? [uploadLink] : [httpLink!]),
    ])
}

const createApolloCache = () =>
    new InMemoryCache({
        // define merge functions https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results
        typePolicies: {
            ApplylistType: ApplylistType(),
            GroupType: GroupType(),
            SearchRequestType: SearchRequestType(),
            BaseUserInterface: BaseUserInterface(),
            BaseUserType: BaseUserType(),
            RecruitingTeamType: RecruitingTeamType(),
            PalletAnalyticsType: PalletAnalyticsType(),
        },
        possibleTypes: {
            BaseUserInterface: ["BaseUserType", "RecruiterType"],
            ErrorInterface: [
                "UserErrorType",
                "ExceededActiveJobPostLimitErrorType",
            ],
            ProfileTypeUnion: [
                "PublicUserProfileType",
                "AnonymousUserProfileType",
            ],
        },
    })

const createApolloClient = (request?: http.IncomingMessage) => {
    return new ApolloClient({
        ssrMode: !hasWindow(),
        link: createIsomorphicLink(request),
        cache: createApolloCache(),
    })
}

export const getApolloInstance = ({
    request,
    initialState,
}: {
    request?: http.IncomingMessage
    initialState?: any
} = {}) => {
    // use Singleton to prevent duplicate instances
    const _apolloClient = apolloClient ?? createApolloClient(request)

    if (initialState) {
        const existingCache = _apolloClient.extract()
        const data = merge(initialState, existingCache, {
            // merge arrays using object equality
            arrayMerge: (destinationArray, sourceArray) => [
                ...sourceArray,
                ...destinationArray.filter(destinationData =>
                    sourceArray.every(
                        sourceData => !isEqual(destinationData, sourceData)
                    )
                ),
            ],
        })
        _apolloClient.cache.restore(data)
    }

    // for SSG and SSR, always create a new Apollo Client
    if (!hasWindow()) return _apolloClient

    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient

    return _apolloClient
}

export const useApollo = (initialState: any) => {
    const store = useMemo(
        () => getApolloInstance({ initialState }),
        [initialState]
    )
    return store
}

/*
 * A wrapper around the Next.js Link component
 * In addition to providing client-side routing, it uses the Pallet Router
 * to automatically append query parameters such as `?pallet=slug` when needed.
 * Reference: https://nextjs.org/docs/api-reference/next/link
 */

import Link, { LinkProps } from "next/link"
import { ReactNode } from "react"
import { usePalletRouter } from "utils/hooks/usePalletRouter"

type NextLinkProps = {
    children: ReactNode
} & LinkProps

const NextLink = ({ href, ...props }: NextLinkProps) => {
    const palletRouter = usePalletRouter()
    return (
        <Link href={palletRouter.createLink(href)} {...props} legacyBehavior />
    )
}

export default NextLink

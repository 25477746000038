import config from "config"
import { useRouter } from "next/router"
import { ReactNode } from "react"
import { IntercomProvider as BaseIntercomProvider } from "react-use-intercom"

const blockedRoutes = [/^\/embed$/, /^\/welcome$/, /^\/onboard\/.*/]

const checkBlockedRoutes = (path: string) => {
    return blockedRoutes.some(regex => regex.test(path))
}

const IntercomProvider = (props: { children: ReactNode }) => {
    const { pathname } = useRouter()
    const isBlockedRoute = checkBlockedRoutes(pathname)

    return (
        <BaseIntercomProvider
            appId={config.INTERCOM_ID}
            shouldInitialize={!isBlockedRoute}
        >
            {props.children}
        </BaseIntercomProvider>
    )
}

export default IntercomProvider

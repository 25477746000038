import { gql } from "@apollo/client"

export const TalentPreferencesViewFragment = gql`
    fragment TalentPreferencesView_me on BaseUserInterface {
        id
        slug
        linkedinUrl
        currentCity {
            id
            geonameId
            shortLabel
            longLabel
        }
        selectedCities {
            id
            geonameId
            shortLabel
            longLabel
        }
        isOpenToRemote
        workStatus
        selectedIndustries {
            id
            slug
            name
        }
    }
`

import { NextRouter } from "next/router"

type TransitionOptions = {
    shallow?: boolean
    locale?: string | false
    scroll?: boolean
}

const removeQueryString = (
    router: NextRouter,
    params: string[],
    options?: TransitionOptions
) => {
    const url = new URL(window.location.href)
    params.forEach(param => url.searchParams.delete(param))
    router.replace(url.toString(), undefined, options)
}

export default removeQueryString

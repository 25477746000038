import styled from "@emotion/styled"
import { notify, Text, TextInputField, theme, ui } from "@pallet-hq/hegel"
import { getApolloInstance } from "apollo/client"
import config from "config"
import { Formik } from "formik"
import Link from "next/link"
import { useState } from "react"
import * as Yup from "yup"
import OnboardingLayout from "components/OnboardingLayout"
import useAuthContext from "state/useAuthContext"
import useLoginModal, { LoginModalStep } from "state/useLoginModal"
import { handleSignup, ValidationErrors } from "utils/auth"
import { SubmitButton } from "./shared"
import ModalHeading from "./ModalHeading"

const PasswordForm = () => {
    const client = getApolloInstance()
    const { refresh } = useAuthContext()
    const { username, hideLoginModal, setActiveStep } = useLoginModal()
    const [formErrors, setFormErrors] = useState<ValidationErrors[]>([])

    return (
        <Formik
            initialValues={{ password: "" }}
            validationSchema={Yup.object().shape({
                password: Yup.string()
                    .required("Password required")
                    .min(8, "Password must be over 8 characters long"),
            })}
            onSubmit={async ({ password }) => {
                const errors = await handleSignup(username, password, true)
                if (errors.length > 0) setFormErrors(errors)
                else {
                    hideLoginModal()
                    setActiveStep(LoginModalStep.EMAIL)
                    await refresh()
                    notify.success(
                        "Welcome to Pallet! You have successfully signed up. Stay tuned for new feature drops."
                    )
                    await client.resetStore()
                }
            }}
        >
            {({ handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <TextInputField
                        name="password"
                        type="password"
                        label="Password"
                        width="100%"
                        placeholder="Create a new password"
                        autoComplete="new-password"
                        sizePreset="large"
                        autoFocus
                    />
                    {formErrors.map((error, index) => (
                        <ui.ErrorMessage
                            key={index}
                            showError
                            message={error.message}
                        />
                    ))}
                    <SubmitButton loading={isSubmitting}>Sign up</SubmitButton>
                    <Text variant="paragraphMedium">
                        To make Pallet work, we log user data and share it with
                        service providers. When clicking “Sign up” above, you
                        accept Pallet’s{" "}
                        <StyledLink
                            href={`${config.MARKETING_SITE}/terms-of-service`}
                            passHref
                        >
                            Terms of Service
                        </StyledLink>
                        {" & "}
                        <StyledLink
                            href={`${config.MARKETING_SITE}/privacy-policy`}
                            passHref
                        >
                            Privacy Policy
                        </StyledLink>
                        .
                    </Text>
                </form>
            )}
        </Formik>
    )
}

const SignupStep = () => (
    <OnboardingLayout
        heading="With your Pallet account, "
        subheading="you can share your profile, apply for jobs, join new collectives, and so much more."
        canGoBack
    >
        <ModalHeading text="Finish signing up" />
        <PasswordForm />
    </OnboardingLayout>
)

export default SignupStep

const StyledLink = styled(Link)`
    text-decoration: underline;
    color: ${theme.colors.gold600};
`

import { useRouter } from "next/router"
import { useEffect } from "react"
import removeQueryString from "../removeQueryString"

export const useQueryParamAction = (
    key: string,
    targetValue: string,
    action: () => void
) => {
    const router = useRouter()

    useEffect(() => {
        const { [key]: value } = router.query

        if (value === targetValue) {
            action()
            removeQueryString(router, [key])
        }
    }, [key, targetValue, action, router])
}

import styled from "@emotion/styled"
import { Box, mq } from "@pallet-hq/hegel"

const FlexOptions = styled.div<{ gap?: string }>`
    display: flex;
    ${({ gap }) => (gap ? `gap: ${gap}` : "")}
`

// Leaving this here for semantic labelling benefits
export const FlexRow = styled(FlexOptions)``

export const FlexColumn = styled(FlexOptions)`
    flex-direction: column;
`

export const FlexStartColumn = styled(FlexColumn)`
    align-items: flex-start;
`

export const CenteredRow = styled(FlexRow)`
    justify-content: center;
`
export const VerticallyCenteredRow = styled(FlexRow)`
    align-items: center;
`
export const SpaceBetweenRow = styled(FlexRow)`
    justify-content: space-between;
`
export const SpaceBetweenVertCenteredRow = styled(VerticallyCenteredRow)`
    justify-content: space-between;
`
export const CenteredColumn = styled(FlexColumn)`
    justify-content: center;
    align-items: center;
`

export const MobileOnly = styled.div`
    ${mq({ display: ["block", "none"] })};
`

export const DesktopOnly = styled.div`
    ${mq({ display: ["none", "block"] })};
`

export const GapBox = styled(Box)<{ gap?: string }>`
    display: flex;
    ${({ gap }) => (gap ? `gap: ${gap}` : "")}
`

export const VerticallyCenteredBox = styled(GapBox)`
    align-items: center;
`

export const CenteredBox = styled(GapBox)`
    align-items: center;
    justify-content: center;
`

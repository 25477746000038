import constate from "constate"
import { useState } from "react"
import { LocationValue } from "components/inputs/Select/LocationSelect"

const [JobPreferencesProvider, useJobPreferences] = constate(() => {
    const [experiences, setExperiences] = useState([] as string[])
    const [locations, setLocations] = useState([] as string[])
    const [jobTypes, setJobTypes] = useState([] as string[])
    const [locations2, setLocations2] = useState([] as LocationValue[])
    const [isOpenToRemote, setIsOpenToRemote] = useState(false)
    const [isOpen, setJobPreferencesIsOpen] = useState(false)

    const [hasPreferences, setHasPreferences] = useState<boolean>(
        !!experiences.length ||
            !!jobTypes.length ||
            !!locations.length ||
            !!locations2.length ||
            isOpenToRemote
    )

    const setJobPreferences = (
        newExperiences: string[] = [],
        newLocations: string[] = [],
        newJobTypes: string[] = [],
        newLocations2: LocationValue[] = [],
        newIsOpenToRemote: boolean = false
    ) => {
        setExperiences(newExperiences)
        setLocations(newLocations)
        setJobTypes(newJobTypes)
        setLocations2(newLocations2)
        setIsOpenToRemote(newIsOpenToRemote)
    }

    const clearPreferences = () => {
        setExperiences([])
        setLocations([])
        setJobTypes([])
        setLocations2([])
        setIsOpenToRemote(false)
    }

    return {
        experiences,
        locations,
        jobTypes,
        locations2,
        isOpenToRemote,
        setJobPreferences,
        clearPreferences,
        isOpen,
        setJobPreferencesIsOpen,
        hasPreferences,
        setHasPreferences,
    }
})

export { JobPreferencesProvider }

export default useJobPreferences

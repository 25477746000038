import styled from "@emotion/styled"
import { mq, Text, theme } from "@pallet-hq/hegel"
import { ReactComponent as GoogleIcon } from "components/icons/logos/google.svg"
import { ReactComponent as LinkedinIcon } from "components/icons/logos/linkedin.svg"
import config from "config"
import { ComponentType } from "react"
import useLoginModal from "state/useLoginModal"

const SocialLogins = () => (
    <SocialLoginsDiv>
        <SingleSocialLogin
            name="Google"
            loginPath="/google"
            icon={GoogleIcon}
        />
        <SingleSocialLogin
            name="LinkedIn"
            loginPath="/linkedin"
            icon={LinkedinIcon}
        />
    </SocialLoginsDiv>
)

type SingleSocialLoginProps = {
    name: string
    loginPath: string
    icon: ComponentType<any>
}

const SingleSocialLogin = (props: SingleSocialLoginProps) => {
    const Icon = props.icon
    const { returnUrl } = useLoginModal()

    let params = new URLSearchParams(window.location.search)
    let fromParam = params.get("from")

    return (
        <SingleSocialLoginLink
            href={`${config.CANONICAL_URL}/api/v1${
                props.loginPath
            }?from=${encodeURIComponent(
                fromParam || returnUrl || window.location.href
            )}`}
        >
            <Icon width={24} height={24} />
            <TextDiv>
                <Text variant="uiSmall500">{props.name}</Text>
            </TextDiv>
        </SingleSocialLoginLink>
    )
}

const SocialLoginsDiv = styled.div`
    display: flex;
    stroke: none;
    gap: ${theme.space.xsmall};
    ${mq({ flexDirection: ["column", "row"] })};
`

const SingleSocialLoginLink = styled.a`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid ${theme.colors.gray400};
    border-radius: 100px;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.12);
    padding: 12px 28px;
    text-decoration: none;
    color: ${theme.colors.gray900};
`

const TextDiv = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    ${mq({ marginLeft: ["-32px", 0] })};
`

export default SocialLogins

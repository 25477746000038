import { gql } from "@apollo/client"

export const ManageLayoutMenuFragment = gql`
    fragment ManageLayoutMenu_applylist on ApplylistType {
        id
        slug
        defaultName
        group {
            id
            applicationsCount: managementCandidatesCount(
                applicationStatus: "SUBMITTED"
            )
            activeCandidatesCount: managementCandidatesCount(
                applicationStatus: "APPROVED"
            )
            upcomingCandidatesCount
            dropCandidatesCount
        }
        logo {
            source
        }
        curatorName
        appliedCompaniesCount: managementSubscriptionsCount(
            approvalStatus: "PENDING"
        )
        activeCompaniesCount: managementSubscriptionsCount(
            approvalStatus: "APPROVED"
        )
        postCount
        reviewCount
        sources {
            id
            name
            kind
            count
            status
        }
    }
`

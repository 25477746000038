export const TRACK_EVENTS = {
    PAGE_VIEWED: "Page Viewed",
    BUTTON_CLICKED: "Button Clicked",
    MODAL_VIEWED: "Modal Viewed",
    POSTING_TIER_CLICKED: "Posting Tier Clicked",
    JOB_POST_CREATED: "Job Post Created",
    JOB_POST_VIEWED: "Job Post Viewed",
    JOB_POST_APPROVED: "Job Post Approved",
    JOB_POST_REJECTED: "Job Post Rejected",
    JOB_SUMMARY_CLICKED: "Job Summary Clicked",
    JOB_DETAILS_VIEWED: "Job Details Viewed",
    JOB_PREFERENCES_UPDATED: "Job Preferences Updated",
    JOB_BOARD_VIEWED: "Job Board Viewed",
    JOB_BOARD_SUBSCRIBED: "Job Board Subscribed",
    JOB_BOARD_UNSUBSCRIBED: "Job Board Unsubscribed",
    JOB_CATEGORY_SELECTED: "Job Category Selected",
    USER_SIGNED_UP: "User Signed Up",
    USER_LOGGED_IN: "User Logged In",
    USER_LOGGED_OUT: "User Logged Out",
    CANDIDATE_INVITED: "Candidate Invited",
    CANDIDATE_APPLICATION_STEP_COMPLETED:
        "Candidate Application Step Completed",
    CANDIDATE_APPLICATION_VIEWED: "Candidate Application Viewed",
    CANDIDATE_PROFILE_VIEWED: "Candidate Profile Viewed",
    POST_A_JOB_STARTED: "Post A Job Started",
    POST_A_JOB_STEP_COMPLETED: "Post A Job Step Completed",
    EDIT_A_JOB_STARTED: "Edit A Job Started",
    EDIT_A_JOB_STEP_COMPLETED: "Edit A Job Step Completed",
    PRICING_OPTION_CLICKED: "Pricing Option Clicked",
    COLLECTIVE_TIER_SELECTED: "Collective Tier Selected",
    CHANGE_PLAN_COMPLETED: "Change Plan Completed",
    HIRING_GOALS_SELECTED: "Hiring Goals Selected",
    CHECKOUT_VIEWED: "Checkout Viewed",
    CANDIDATE_COMMENT_ADDED: "CANDIDATE_COMMENT_ADDED",
    CANDIDATE_COMMENT_UPDATED: "CANDIDATE_COMMENT_UPDATED",
    CANDIDATE_COMMENT_DELETED: "CANDIDATE_COMMENT_DELETED",
    CANDIDATE_APPROVAL_METHOD_UPDATED: "CANDIDATE_APPROVAL_METHOD_UPDATED",
    COMMUNITIES_FILTERS_UPDATED: "COMMUNITIES_FILTERS_UPDATED",
    TEAM_COMPANY_PROFILE_VIEWED: "Team Company Profile Viewed",
}

export const BUTTON_TYPES = {
    APPLY: "APPLY",
    COMPANY: "COMPANY",
    PALLET_LOGO: "PALLET_LOGO",
    POST_A_JOB: "POST_A_JOB",
    HIRE_TALENT: "HIRE_TALENT",
    APPROVE_ALL_POSTS: "APPROVE_ALL_POSTS",
    COPY_INVITE_LINK: "COPY_INVITE_LINK",
    REFER_A_CANDIDATE: "REFER_A_CANDIDATE",
    EDIT_CANDIDATE_PROFILE: "EDIT_CANDIDATE_PROFILE",
    INVITE_TO_COLLECTIVE: "INVITE_TO_COLLECTIVE",
    INTRO_REQUEST_ACCEPTED: "INTRO_REQUEST_ACCEPTED",
    INTRO_REQUEST_REJECTED: "INTRO_REQUEST_REJECTED",
    APPLY_TO_COLLECTIVE: "APPLY_TO_COLLECTIVE",
    START_HIRING: "START_HIRING",
    APPROVE_CANDIDATE: "APPROVED_CANDIDATE",
    REJECT_CANDIDATE: "REJECT_CANDIDATE",
    APPROVE_COMPANY: "APPROVE_COMPANY",
    REJECT_COMPANY: "REJECT_COMPANY",
    REQUEST_INTRO: "REQUEST_INTRO",
    LIKE_TEAM: "LIKE_TEAM",
    DEFER_CANDIDATE: "DEFER_CANDIDATE",
    SAVE_CANDIDATE: "SAVE_CANDIDATE",
    UNSAVE_CANDIDATE: "UNSAVE_CANDIDATE",
    SKIP_CANDIDATE: "SKIP_CANDIDATE",
    UNSKIP_CANDIDATE: "UNSKIP_CANDIDATE",
    SUBSCRIBE: "SUBSCRIBE",
    CHANGE_PLAN: "CHANGE_PLAN",
    NOT_INTERESTED_IN_COLLECTIVE_UPSELL_WHEN_POSTING:
        "NOT_INTERESTED_IN_COLLECTIVE_UPSELL_WHEN_POSTING",
    COLLECTIVE_UPSELL_WHEN_POSTING: "COLLECTIVE_UPSELL_WHEN_POSTING",
    NEXT_CANDIDATE: "NEXT_CANDIDATE",
    PREVIOUS_CANDIDATE: "PREVIOUS_CANDIDATE",
    SEND_INTRO_REQUEST: "SEND_INTRO_REQUEST",
    BULK_SEND_INTRO_REQUEST: "BULK_SEND_INTRO_REQUEST",
    SHARE_CANDIDATE: "SHARE_CANDIDATE",
    CHECKOUT_SUBMIT: "CHECKOUT_SUBMIT",
    CHECKOUT_CANCEL: "CHECKOUT_CANCEL",
    MY_POSTS_VIEW_LISTING: "MY_POSTS_VIEW_LISTING",
    MY_POSTS_EDIT_LISTING: "MY_POSTS_EDIT_LISTING",
    MY_POSTS_VIEW_POST: "MY_POSTS_VIEW_POST",
    MY_POSTS_EDIT_POST: "MY_POSTS_EDIT_POST",
    MY_POSTS_REPOST_LISTING: "MY_POSTS_REPOST_LISTING",
    MY_POSTS_DELETE_LISTING: "MY_POSTS_DELETE_LISTING",
    MY_POSTS_TOGGLE_LISTING_ENABLED: "MY_POSTS_TOGGLE_LISTING_ENABLED",
    MY_POSTS_CREATE_JOB: "MY_POSTS_CREATE_JOB",
    UPDATE_PROFILE_IN_CALLBACK: "UPDATE_PROFILE_IN_CALLBACK",
    CREATE_JOB: "CREATE_JOB",
    SKIP_JOB_SELECTION: "SKIP_JOB_SELECTION",
    MEET_CANDIDATES: "MEET_CANDIDATES",
    SETUP_RECRUITER_ACCOUNT: "SETUP_RECRUITER_ACCOUNT",
    SELECT_EXISTING_JOB: "SELECT_EXISTING_JOB",
    PARTNERSHIP_MENU_MESSAGE_CURATOR: "PARTNERSHIP_MENU_MESSAGE_CURATOR",
    PARTNERSHIP_MENU_RESTART_SUBSCRIPTION:
        "PARTNERSHIP_MENU_RESTART_SUBSCRIPTION",
    PARTNERSHIP_MENU_CHANGE_PLAN: "PARTNERSHIP_MENU_CHANGE_PLAN",
    PARTNERSHIP_MENU_CANCEL_SUBSCRIPTION:
        "PARTNERSHIP_MENU_CANCEL_SUBSCRIPTION",
    PARTNERSHIP_MENU_REPURCHASE_SUBSCRIPTION:
        "PARTNERSHIP_MENU_REPURCHASE_SUBSCRIPTION",
    RECOMMENDED_COMMUNITIES_REQUEST_ACCESS:
        "RECOMMENDED_COMMUNITIES_REQUEST_ACCESS",
    RECOMMENDED_COMMUNITIES_UPGRADE_TO_PRO:
        "RECOMMENDED_COMMUNITIES_UPGRADE_TO_PRO",
    RECOMMENDED_COMMUNITIES_UPGRADE_LEARN_MORE:
        "RECOMMENDED_COMMUNITIES_UPGRADE_LEARN_MORE",
    FREEMIUM_UPGRADE_TO_PRO: "FREEMIUM_UPGRADE_TO_PRO",
    CREATE_PRESET_PROJECT: "CREATE_PRESET_PROJECT",
    OPEN_PRESET_PROJECT: "OPEN_PRESET_PROJECT",
    SEE_CANDIDATES_FOR_POST: "SEE_CANDIDATES_FOR_POST",
}

export const MODAL_TYPES = {
    JOB_BOARD_SUBSCRIBE: "JOB_BOARD_SUBSCRIBE",
    JOB_BOARD_UNSUBSCRIBE: "JOB_BOARD_UNSUBSCRIBE",
    JOB_BOARD_CANDIDATE_UPSELL: "JOB_BOARD_CANDIDATE_UPSELL",
    UPSELL_POSTING_TO_COLLECTIVE_MODAL: "UPSELL_POSTING_TO_COLLECTIVE_MODAL",
    UPDATE_PROFILE_CALLBACK_MODAL: "UPDATE_PROFILE_CALLBACK_MODAL",
    MY_POSTS_DELETE_POST: "MY_POSTS_DELETE_POST",
    ONBOARDING_AUTH: "ONBOARDING_AUTH",
}

// New events: We make more events instead of relying on
// general events like "Button Clicked" or "Page Viewed"
export const EVENTS = {
    VIEW_TALENT_WELCOME: "View Talent Welcome",
    VIEW_COMPANIES: "View Companies",
    VIEW_JOBS: "View Jobs",
    COLLECTIVE_CLICK_APPLY: "Click Apply (Collective)",
    COLLECTIVE_START_APPLICATION: "Start Application (Collective)",
    COLLECTIVE_SUBMIT_APPLICATION: "Submit Application (Collective)",
    COLLECTIVE_SET_PREFERENCES: "Set Preferences (Collective)",
    COLLECTIVE_SET_WORK_DETAILS: "Set Work Details (Collective)",
    JOBS_SUBSCRIBE: "Subscribe (Job Board)",
    JOBS_CLICK_FOLLOW: "Click Follow (Job Board)",
    JOBS_CLICK_UNFOLLOW: "Click Unfollow (Job Board)",
}

import { useQuery } from "@apollo/client"
import {
    AsyncMultiSelect,
    AsyncMultiSelectProps,
    AsyncSingleSelect,
    AsyncSingleSelectProps,
} from "@pallet-hq/hegel"
import { LOCATIONS_QUERY } from "./queries"
import {
    LocationsQuery,
    LocationsQuery_locations2 as LocationEdges,
    LocationsQuery_locations2_edges_node as Location,
} from "__generated__/LocationsQuery"
import { LocationTypeEnum } from "__generated__/globalTypes"

type _LocationSelectProps = AsyncSingleSelectProps | AsyncMultiSelectProps
type LocationSelectProps = Omit<
    _LocationSelectProps,
    "loadOptions" | "closeMenuOnSelect"
> & {
    isMulti: boolean
}

export type LocationValue = {
    geonameId: number
    locationType: LocationTypeEnum
}

export type LocationOption = {
    value: LocationValue
    label: string
    abbreviatedLabel: string
    data?: {
        value: string
        [key: string]: string
    }
}

const getValue = (option: LocationOption): LocationValue => {
    return option.value
}

export const getValues = (options: LocationOption[]): LocationValue[] => {
    return options.map(option => getValue(option))
}

export const getOptions = (locations: Location[]): LocationOption[] => {
    return locations.map(location => ({
        value: {
            geonameId: location.geonameId,
            locationType: location.locationType,
        },
        label: location.longLabel,
        abbreviatedLabel: location.shortLabel,
    }))
}

const getOptionsFromEdges = (locations: LocationEdges): LocationOption[] => {
    return locations.edges.map(edge => ({
        value: {
            geonameId: edge!.node!.geonameId,
            locationType: edge!.node!.locationType,
        },
        label: edge!.node!.longLabel,
        abbreviatedLabel: edge!.node!.shortLabel,
    }))
}

export const isOptionEqual = (
    option: LocationOption,
    other: LocationOption
) => {
    return option.value.geonameId === other.value.geonameId
}

const LocationSelect = (props: LocationSelectProps) => {
    const SelectComponent = props.isMulti ? AsyncMultiSelect : AsyncSingleSelect
    const { data, refetch } = useQuery<LocationsQuery>(LOCATIONS_QUERY, {
        variables: { searchInput: "", first: 20 },
    })

    return (
        <SelectComponent
            {...props}
            closeMenuOnSelect
            defaultOptions={
                data?.locations2 ? getOptionsFromEdges(data.locations2) : []
            }
            loadOptions={async (searchInput: string) => {
                if (!searchInput) {
                    return []
                }
                let response = await refetch({
                    searchInput: searchInput,
                    first: 20,
                })
                return getOptionsFromEdges(response.data.locations2)
            }}
            isSearchable
            getOptionValue={(option: LocationOption) => option.value.geonameId}
            openMenuOnClick={false}
            openMenuOnFocus={false}
        />
    )
}

export default LocationSelect

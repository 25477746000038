import { useQuery } from "@apollo/client"
import {
    getSelectOptions,
    getSelectOptions2,
    SelectOptions,
} from "components/inputs/Select/utils"
import {
    LocationOption,
    getOptions,
} from "components/inputs/Select/LocationSelect"
import { JOB_BOARD_PREFERENCES_QUERY } from "./queries"
import useJobPreferences from "./useJobPreferences"
import { JobBoardPreferenceQuery } from "__generated__/JobBoardPreferenceQuery"

export const useInitialJobBoardPreferences = () => {
    const { locations, jobTypes, experiences, setHasPreferences } =
        useJobPreferences()
    const { loading, error, data } = useQuery<JobBoardPreferenceQuery>(
        JOB_BOARD_PREFERENCES_QUERY
    )
    let initialLocations: SelectOptions = []
    let initialExperiences: SelectOptions = []
    let initialJobTypes: SelectOptions = []
    let initialOpenToRemote: boolean = false
    let initialLocations2: LocationOption[] = []

    if (data && data.me) {
        initialLocations = getSelectOptions2(
            data.me.selectedJobBoardLocations.edges.filter(
                edge => edge?.node?.shortName !== "Remote"
            ),
            "shortName",
            "fullName"
        )
        initialExperiences = getSelectOptions(
            data.me.selectedJobBoardExperiences,
            "slug",
            "name"
        )
        initialJobTypes = getSelectOptions2(
            data.me.selectedJobBoardJobTypes.edges,
            "slug",
            "name"
        )
        initialOpenToRemote = data.me.selectedJobBoardIsOpenToRemote
        initialLocations2 = getOptions(data.me.selectedJobBoardLocations2)

        // set the existence of preferences on the load of an authed user
        setHasPreferences(
            !!initialLocations.length ||
                !!initialExperiences.length ||
                !!initialJobTypes.length ||
                !!initialLocations2.length ||
                initialOpenToRemote
        )
    } else if (data) {
        initialLocations = getSelectOptions(
            data.locations.filter(loc => loc.shortName !== "Remote"),
            "shortName",
            "fullName"
        ).filter(({ value }) => locations.includes(value))
        initialExperiences = getSelectOptions(
            data.experience,
            "slug",
            "name"
        ).filter(({ value }) => experiences.includes(value))
        initialJobTypes = getSelectOptions(
            data.jobTypes,
            "slug",
            "name"
        ).filter(({ value }) => jobTypes.includes(value))
        // set the existence of preferences on the load of an unauthed user
        setHasPreferences(
            !!initialLocations.length ||
                !!initialExperiences.length ||
                !!initialJobTypes.length ||
                !!initialLocations2.length ||
                initialOpenToRemote
        )
    }

    return {
        initialValues: {
            locations: initialLocations,
            experienceLevels: initialExperiences,
            jobTypes: initialJobTypes,
            openToRemote: initialOpenToRemote,
            locations2: initialLocations2,
        },
        loading,
        error,
        data,
    }
}

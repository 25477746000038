import { useMutation } from "@apollo/client"
import styled from "@emotion/styled"
import {
    Button,
    FormModal,
    FullPageModal,
    notify,
    Text,
    theme,
} from "@pallet-hq/hegel"
import { Form, Formik } from "formik"
import mixpanel from "mixpanel-browser"
import { FlexColumn } from "components/atoms"
import { getValues } from "components/inputs/Select/LocationSelect"
import { TRACK_EVENTS } from "constants/events"
import { UPDATE_JOB_PREFERENCES_MUTATION } from "state/jobPreferences/queries"
import { useInitialJobBoardPreferences } from "state/jobPreferences/useInitialJobBoardPreferences"
import useJobPreferences from "state/jobPreferences/useJobPreferences"
import { useScreenSize } from "utils/hooks/useSize"
import JobPreferencesModalContents from "./JobPreferencesModalContents"
import { UpdateJobPreferences } from "__generated__/UpdateJobPreferences"

const JobPreferencesModal = () => {
    const [updateJobPreferences] = useMutation<UpdateJobPreferences>(
        UPDATE_JOB_PREFERENCES_MUTATION
    )
    const { loading, error, data, initialValues } =
        useInitialJobBoardPreferences()
    const { isOpen, setJobPreferences, setJobPreferencesIsOpen } =
        useJobPreferences()
    const { isMobile } = useScreenSize()
    if (loading || error || !data) return null

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={async values => {
                const experiences = values.experienceLevels.map(
                    value => value.value
                )
                const locations = values.locations.map(value => value.value)
                if (values.openToRemote) {
                    locations.push("Remote")
                }
                const locations2 = getValues(values.locations2)
                const jobTypes = values.jobTypes.map(value => value.value)
                mixpanel.track(TRACK_EVENTS.JOB_PREFERENCES_UPDATED, {
                    experience_levels: experiences,
                    job_types: jobTypes,
                    locations2: locations2,
                })
                setJobPreferences(
                    experiences,
                    locations,
                    jobTypes,
                    locations2,
                    values.openToRemote
                )
                updateJobPreferences({
                    variables: {
                        selectedExperience: experiences,
                        jobTypes,
                        selectedLocations2: locations2,
                        isOpenToRemote: values.openToRemote,
                    },
                }).then(() => {
                    setJobPreferencesIsOpen(false)
                    notify.success(
                        "Successfully updated your job preferences 😎"
                    )
                })
            }}
        >
            <>
                {isMobile ? (
                    <FullPageModal
                        isOpen={isOpen}
                        hideModal={() => setJobPreferencesIsOpen(false)}
                        showIcon={false}
                        margin={theme.space.small}
                    >
                        <ModalTitle variant="headlineSmall" margin={"0px"}>
                            Job board filters
                        </ModalTitle>
                        <Form>
                            <FlexColumn
                                style={{ paddingTop: theme.space.large }}
                            >
                                <JobPreferencesModalContents data={data} />
                                <Controls>
                                    <Button
                                        type="submit"
                                        size="small"
                                        width="100%"
                                    >
                                        Save changes
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={() =>
                                            setJobPreferencesIsOpen(false)
                                        }
                                        size="small"
                                        width="100%"
                                    >
                                        Cancel
                                    </Button>
                                </Controls>
                            </FlexColumn>
                        </Form>
                    </FullPageModal>
                ) : (
                    <FormModal
                        title="Job board filters"
                        action="Save changes"
                        width="medium"
                        isOpen={isOpen}
                        hideModal={() => setJobPreferencesIsOpen(false)}
                        withCancel
                    >
                        <JobPreferencesModalContents data={data} />
                    </FormModal>
                )}
            </>
        </Formik>
    )
}

export default JobPreferencesModal

const ModalTitle = styled(Text)`
    position: fixed;
    width: 100%;
    background: ${theme.colors.white};
    padding: ${theme.space.small};
    z-index: 99;
    top: 0;
    left: 0;
`

const Controls = styled(FlexColumn)`
    width: 100%;
    box-sizing: border-box;
    padding: ${theme.space.small};
    position: fixed;
    bottom: 0;
    left: 0;
    background: ${theme.colors.gray100};
    gap: ${theme.space.small};
`

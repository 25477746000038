import styled from "@emotion/styled"
import { Text, theme } from "@pallet-hq/hegel"

const OrDivider = () => (
    <OrDividerLine>
        <Text variant="paragraphMedium" bg="white" px="xxsmall" color="gray900">
            or continue with
        </Text>
    </OrDividerLine>
)

const OrDividerLine = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 1px;
    background: ${theme.colors.gray300};
    margin-bottom: 32px;
`

export default OrDivider

import { gql } from "@apollo/client"

export const TalentWorkDetailsViewFragment = gql`
    fragment TalentWorkDetailsView_me on BaseUserInterface {
        id
        slug
        currentJobType {
            id
            slug
            shortLabel
            longLabel
        }
        yearsOfExperience
        hasNoWorkExperience
        currentTitle
        recentEmployer {
            id
            slug
            name
            image
        }
    }
`

import { useEffect, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import { BackButtonQuery } from "__generated__/BackButtonQuery"
import { usePalletContext } from "./usePalletContext"

const BACK_BUTTON_QUERY = gql`
    query BackButtonQuery($slug: String!) {
        applylist(slug: $slug) {
            ... on ApplylistType {
                id
                name
                logo {
                    source
                }
                slug
            }
        }
    }
`

export const useBackPageRoute = () => {
    const [backPage, setBackPage] = useState<BackButtonQuery>()

    const { pallet } = usePalletContext()

    const { data, loading } = useQuery<BackButtonQuery>(BACK_BUTTON_QUERY, {
        variables: { slug: pallet! },
    })

    useEffect(() => {
        if (data && !loading) {
            setBackPage(data)
        }
    }, [data, loading])
    return backPage
}

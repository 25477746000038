import { TRACK_EVENTS } from "constants/events"
import mixpanel from "mixpanel-browser"
import { useRouter } from "next/router"
import { useEffect } from "react"

const trackPageView = () => {
    mixpanel.track(TRACK_EVENTS.PAGE_VIEWED, { path: window.location.pathname })
}

export const useMixpanelTracking = () => {
    const router = useRouter()

    useEffect(() => {
        // send page event on initial page load
        trackPageView()

        // send page event on all client-side route changes
        router.events.on("routeChangeComplete", trackPageView)

        return () => {
            router.events.off("routeChangeComplete", trackPageView)
        }
    }, [router])
}

import queryString from "query-string"
import { ApolloClient, gql } from "@apollo/client"
import { getApolloInstance } from "apollo/client"
import config from "config"
import { TRACK_EVENTS } from "constants/events"
import mixpanel from "mixpanel-browser"
import { GetServerSidePropsContext } from "next"
import Router, { NextRouter } from "next/router"
import { appendQueryParams, PalletContext } from "utils/router"
import { CheckAuthQuery } from "__generated__/CheckAuthQuery"
import { CreateLoginTokenMutation } from "__generated__/CreateLoginTokenMutation"
import { LoginViaEmail } from "__generated__/LoginViaEmail"
import { SignUpViaEmail } from "__generated__/SignUpViaEmail"

export const CHECK_AUTH_QUERY = gql`
    query CheckAuthQuery {
        me {
            id
        }
    }
`

export const CHECK_RECRUITER_AUTH_QUERY = gql`
    query CheckRecruiterAuthQuery {
        me {
            id
            currentTeam {
                id
                uuid
            }
        }
    }
`

export const CREATE_LOGIN_TOKEN_MUTATION = gql`
    mutation CreateLoginTokenMutation {
        createLoginToken {
            xtokenx
        }
    }
`

export const LOGIN_VIA_EMAIL = gql`
    mutation LoginViaEmail($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            user {
                id
                profileImageUrl
                firstName
                lastName
                slug
            }
            xtokenx
            validationErrors {
                message
                errorCode
            }
        }
    }
`

export const SIGN_UP_VIA_EMAIL = gql`
    mutation SignUpViaEmail(
        $email: String!
        $password: String!
        $firstName: String
        $lastName: String
    ) {
        signUp(
            email: $email
            password: $password
            firstName: $firstName
            lastName: $lastName
        ) {
            user {
                id
                profileImageUrl
                firstName
                lastName
                email
                slug
            }
            xtokenx
            validationErrors {
                message
                errorCode
            }
        }
    }
`

/*
 * Get the URL that the user came from to the page.
 * If there is no initial URL (user came directly to the login page),
 * then default to null.
 */
export const getInitialUrl = (): URL | null => {
    let initialUrl = new URL(window.location.href)
    let fromUrl = initialUrl.searchParams.get("from")
    if (fromUrl) {
        let parsedUrl = new URL(fromUrl)
        return parsedUrl
    } else {
        return null
    }
}

export type ValidationErrors = {
    message: string
}

type ProfileArgs = {
    firstName: string
    lastName: string
}

type authHandler = (
    email: string,
    password: string,
    redirect: boolean,
    profileArgs?: ProfileArgs
) => Promise<ValidationErrors[]>

type AuthEventData = {
    initialUrl: URL | null
    event: "login" | "signUp"
    xtokenx: string | null
}

/*
 * Handle the redirection logic post authentication. This assumes that
 * authentication was successful.
 *
 * This function can only run client side.
 */
export const handlePostAuthentication = (data: AuthEventData) => {
    if (
        data.initialUrl === null ||
        (data.initialUrl.host === new URL(config.CANONICAL_URL).host &&
            data.initialUrl.pathname === "/login")
    ) {
        if (data.event === "signUp") {
            Router.push("/welcome")
        } else {
            Router.push("/")
        }
    } else {
        /* If the xtokenx parameter is present, redirect to
         * initialUrl's proxied Huxley bounce view with token as
         * a parameter once more.
         *
         * Bounce is the step that will implicitly auth in custom domain.
         */
        if (
            data.xtokenx &&
            data.initialUrl.host !== new URL(config.CANONICAL_URL).host
        ) {
            window.location.assign(
                appendQueryParams(data.initialUrl.origin + "/api/v1/bounce", {
                    xtokenx: data.xtokenx,
                    destination: data.initialUrl.href,
                })
            )
        } else {
            window.location.assign(data.initialUrl)
        }
    }
}

/*
 * Handle the native login of a user (as opposed to social login).
 */
const _handleLogin: authHandler = async (
    email: string,
    password: string,
    redirect: boolean = true,
    profileArgs?: ProfileArgs
) => {
    const client = getApolloInstance()
    let { data } = await client.mutate<LoginViaEmail>({
        mutation: LOGIN_VIA_EMAIL,
        variables: { email, password, ...profileArgs },
    })
    // Update the root type with a reference to the logged in user.
    client.writeQuery({
        query: CHECK_AUTH_QUERY,
        data: {
            me: data?.login?.user,
        },
    })
    if (data?.login && !data.login.validationErrors) {
        mixpanel.track(TRACK_EVENTS.USER_LOGGED_IN, {
            via: "email",
            is_passwordless: false,
        })
        let initialUrl = getInitialUrl()
        if (redirect) {
            handlePostAuthentication({
                initialUrl: initialUrl,
                event: "login",
                xtokenx: data.login.xtokenx,
            })
        }
    } else {
        return (
            data?.login?.validationErrors!.map(error => ({
                message: error.message,
            })) ?? []
        )
    }
    return [] // For ts compiler, redirect or return already happened.
}

/*
 * Handle the native signup of a user (as opposed to social signup).
 */
const _handleSignup: authHandler = async (
    email: string,
    password: string,
    redirect: boolean = true,
    profileArgs?: ProfileArgs
) => {
    const client = getApolloInstance()
    let { data } = await client.mutate<SignUpViaEmail>({
        mutation: SIGN_UP_VIA_EMAIL,
        variables: { email, password, ...profileArgs },
    })
    // Update the root type with a reference to the signed up user.
    client.writeQuery({
        query: CHECK_AUTH_QUERY,
        data: {
            me: data?.signUp?.user,
        },
    })

    if (data?.signUp?.user?.email && !data.signUp.validationErrors) {
        mixpanel.alias(data.signUp.user.email)
        mixpanel.track(TRACK_EVENTS.USER_SIGNED_UP, {
            via: "email",
            is_passwordless: false,
        })
        let initialUrl = getInitialUrl()
        if (redirect) {
            handlePostAuthentication({
                initialUrl: initialUrl,
                event: "signUp",
                xtokenx: data.signUp.xtokenx,
            })
        }
    } else {
        return (
            data?.signUp?.validationErrors!.map(error => ({
                message: error.message,
            })) ?? []
        )
    }
    return [] // For ts compiler, redirect or return already happened.
}

/*
 * Error catch-all function wrapper for auth handlers.
 */
const safelyHandleAuthWrapper = (authHandlerFn: authHandler): authHandler => {
    let safeHandleAuthFun: authHandler = (
        email: string,
        password: string,
        redirect: boolean = true,
        profileArgs?: ProfileArgs
    ) => {
        try {
            return authHandlerFn(email, password, redirect, profileArgs)
        } catch (errors: any) {
            return errors.graphQLErrors
                .map((error: ValidationErrors) => ({ message: error.message }))
                .concat(
                    errors.networkError
                        ? [
                              {
                                  message:
                                      "It appears as though you're offline...",
                              },
                          ]
                        : [{ message: "Something went wrong..." }]
                )
        }
    }
    return safeHandleAuthFun
}

/*
 * Safe authentication handlers for native login/signup.
 */
export const handleLogin = safelyHandleAuthWrapper(_handleLogin)
export const handleSignup = safelyHandleAuthWrapper(_handleSignup)

/*
 * Handle the social authentication (both login and signup) of a user.
 */
export const handleSocialAuth = () => {
    let searchParams = new URL(window.location.href).searchParams

    // Some search params are expected from the backend redirect.
    let direct = searchParams.get("direct")
    let email = searchParams.get("email")
    let created = searchParams.get("created")
    let xtokenx = searchParams.get("xtokenx")
    let isSignup = created === "true"

    // Track authentication event
    if (created && email) {
        if (isSignup) {
            mixpanel.alias(email)
        }
        mixpanel.track(
            isSignup
                ? TRACK_EVENTS.USER_SIGNED_UP
                : TRACK_EVENTS.USER_LOGGED_IN,
            { via: "social", is_passwordless: false }
        )
    }

    // Redirect the authenticated user.
    if (direct) {
        let initialUrl = new URL(direct)
        handlePostAuthentication({
            initialUrl: initialUrl,
            event: isSignup ? "signUp" : "login",
            xtokenx: xtokenx,
        })
    }
}

/**
 * Returns a NextJS redirect object if the current user is not logged in.
 * Should only be used in getServerSideProps.
 * @param apolloClient - Apollo Client instance
 * @param context - SSR Context
 * @param email - an optional email address used to pre-populate the login form
 * @returns a NextJS redirect object if not authed, null otherwise
 */
export const buildRedirectIfNotAuthenticated = async (
    apolloClient: ApolloClient<any>,
    context: GetServerSidePropsContext,
    email?: string
) => {
    const authResult = await apolloClient.query<CheckAuthQuery>({
        query: CHECK_AUTH_QUERY,
    })

    if (!authResult.data.me) {
        const { host } = context.req.headers
        const baseUrl = host ? `http://${host}` : config.CANONICAL_URL
        const currentUrl = `${baseUrl}${context.resolvedUrl}`
        return {
            redirect: {
                permanent: false,
                destination: queryString.stringifyUrl({
                    url: `${config.CANONICAL_URL}/login`,
                    query: {
                        from: currentUrl,
                        email,
                    },
                }),
            },
        }
    } else {
        return null
    }
}

/**
 * Signing up and logging in under a custom domain means that the user will not
 * authenticated under the CANONICAL_DOMAIN (i.e. app.pallet.com). This helper
 * function solves this issue when navigating from a custom domain url to a
 * canonical domain url such as the user's profile page. If on a custom domain,
 * an xtokenx is retrieved and then sent to the `bounce` endpoint which
 * redirects the user to their destination and preserves their auth session.
 */
export const fetchAuthTokenAndBounce = async (
    palletContext: PalletContext,
    router: NextRouter,
    destinationUrl: string
) => {
    if (palletContext.isCustomDomain) {
        const apolloClient = getApolloInstance()
        const { data } = await apolloClient.mutate<CreateLoginTokenMutation>({
            mutation: CREATE_LOGIN_TOKEN_MUTATION,
            fetchPolicy: "no-cache", // don't store auth token in cache
        })
        if (data?.createLoginToken?.xtokenx) {
            return window.location.assign(
                `${window.location.origin}/api/v1/bounce?xtokenx=${data.createLoginToken.xtokenx}&destination=${destinationUrl}`
            )
        }
    }

    return router.push(destinationUrl)
}

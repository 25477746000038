import { Modal } from "@pallet-hq/hegel"
import config from "config"
import { useEffect } from "react"
import useLoginModal, { LoginModalStep } from "state/useLoginModal"
import { appendQueryParams } from "utils/router"
import EmailStep from "./EmailStep"
import LoginStep from "./LoginStep"
import PasswordlessStep from "./PasswordlessStep"
import SignupStep from "./SignupStep"

export const LoginForm = () => {
    const { activeStep } = useLoginModal()

    switch (activeStep) {
        case LoginModalStep.LOGIN:
            return <LoginStep />
        case LoginModalStep.SIGNUP:
            return <SignupStep />
        case LoginModalStep.PASSWORDLESS:
            return <PasswordlessStep />
        case LoginModalStep.EMAIL:
        default:
            return <EmailStep />
    }
}

export const LoginFormRedirectReplacement = () => {
    useEffect(() => {
        window.location.assign(
            appendQueryParams(config.CANONICAL_URL + "/login", {
                from: window.location.href,
            })
        )
    })
    return null
}

const LoginModal = () => {
    const { isVisible, hideLoginModal } = useLoginModal()

    return (
        <Modal
            showIcon={false}
            hideModal={hideLoginModal}
            isOpen={isVisible}
            width="100%"
            maxHeight="none"
        >
            <LoginForm />
        </Modal>
    )
}

export default LoginModal

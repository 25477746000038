import styled from "@emotion/styled"
import { Text, theme } from "@pallet-hq/hegel"
import OnboardingLayout from "components/OnboardingLayout"
import config from "config"
import useLoginModal from "state/useLoginModal"
import { usePalletRouter } from "utils/hooks/usePalletRouter"
import { SubmitButton } from "./shared"
import ModalHeading from "./ModalHeading"

const PasswordlessStep = () => {
    const { username } = useLoginModal()
    const { push } = usePalletRouter()

    return (
        <OnboardingLayout
            heading="With your Pallet account, "
            subheading="you can share your profile, apply for jobs, join new collectives, and so much more."
            canGoBack
        >
            <ModalHeading text="Verify your account" />
            <Text variant="paragraphLarge">
                We’ve sent an email to{" "}
                <LinkText href={`mailto:${username}`}>{username}</LinkText>.
                Please check your inbox and follow the instructions to create a
                password and complete your account setup.
            </Text>
            <br />
            <Text variant="paragraphLarge">
                Press Continue below to go to the homepage.
            </Text>
            <SubmitButton onClick={() => push(config.MARKETING_SITE)}>
                Continue
            </SubmitButton>
        </OnboardingLayout>
    )
}

export default PasswordlessStep

const LinkText = styled.a`
    text-decoration: underline;
    color: ${theme.colors.darkGold};
`

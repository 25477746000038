import styled from "@emotion/styled"
import { textStyles, theme } from "@pallet-hq/hegel"
import FeedbackModal from "components/Modals/FeedbackModal"
import { useState } from "react"

import { Popover } from "react-tiny-popover"
import { FeedbackModalProvider } from "state/useFeedbackModal"
import useHelpMenu from "state/useHelpMenu"
import { disallowSelect } from "utils/styles/shared"
import HelpMenuContent from "./HelpMenuContent"

const HelpMenu = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { isVisible } = useHelpMenu()

    if (!isVisible) return null
    return (
        <FeedbackModalProvider>
            <FeedbackModal />
            <Popover
                isOpen={isOpen}
                positions={["top", "left"]}
                padding={8}
                onClickOutside={() => setIsOpen(false)}
                align="end"
                content={<HelpMenuContent closeMenu={() => setIsOpen(false)} />}
            >
                <ButtonDiv
                    onClick={() =>
                        setIsOpen(isCurrentlyOpen => !isCurrentlyOpen)
                    }
                >
                    ?
                </ButtonDiv>
            </Popover>
        </FeedbackModalProvider>
    )
}

const ButtonDiv = styled.div`
    position: fixed;
    display: flex;
    z-index: 214748299; /* Intercom index - 1 */
    justify-content: center;
    align-items: center;
    bottom: ${theme.space.large};
    right: ${theme.space.large};
    height: ${theme.space.large};
    width: ${theme.space.large};
    ${textStyles.headlineSmall};
    font-weight: 500;
    line-height: 18px;
    border-radius: 50%;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.gray200};
    box-shadow: 0px 30px 12px rgba(0, 0, 0, 0.01),
        0px 17px 10px rgba(0, 0, 0, 0.03), 0px 7px 7px rgba(0, 0, 0, 0.05),
        0px 2px 4px rgba(0, 0, 0, 0.06), 0px 0px 0px rgba(0, 0, 0, 0.06);
    ${disallowSelect}
    cursor: pointer;
`

export default HelpMenu

import { FieldPolicy } from "@apollo/client"
import { PaginatedDataFieldPolicy } from "./paginatedDataField"

/**
 * Field Policy for paginated candidates with filters, where filters
 * is an instance of SearchCandidatesFilterInputType.
 *
 * Used in inbound candidate feeds in the recruiter app.
 */
export const LikedByCandidatesFieldPolicy = (): FieldPolicy => {
    return PaginatedDataFieldPolicy((args: any) => {
        const keyArgs = ["filters"]
        // make a new cache object if pagination is not used
        return !args?.first ? [...keyArgs, "after"] : keyArgs
    })
}

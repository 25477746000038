import constate from "constate"
import { useEffect, useState } from "react"
import { usePalletRouter } from "utils/hooks/usePalletRouter"
import removeQueryString from "utils/removeQueryString"

export enum LoginModalStep {
    EMAIL = 0,
    LOGIN = 1,
    SIGNUP = 2,
    PASSWORDLESS = 3,
}

const [LoginModalProvider, useLoginModal] = constate(() => {
    const [isVisible, setVisibility] = useState(false)
    const router = usePalletRouter()
    const { email } = router.query
    const [activeStep, setActiveStep] = useState(LoginModalStep.EMAIL)
    const [username, setUsername] = useState("")
    const [returnUrl, setReturnUrl] = useState("")
    useEffect(() => {
        if (email && isVisible) {
            setUsername(email as string)
            removeQueryString(router, ["email"], { shallow: true })
        }
    }, [email, router, isVisible])

    const showLoginModal = () => setVisibility(true)
    const hideLoginModal = () => {
        if (returnUrl) setReturnUrl("")
        setVisibility(false)
    }

    return {
        isVisible,
        hideLoginModal,
        showLoginModal,
        setReturnUrl,
        returnUrl,
        activeStep,
        setActiveStep,
        username,
        setUsername,
    }
})

export { LoginModalProvider }

export default useLoginModal

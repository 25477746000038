import { gql } from "@apollo/client"

export const CandidateSectionFragment = gql`
    fragment CandidateSection_candidate on CandidateType {
        id
        user {
            id
            fullName
            profileImageUrl
            privacyStatus
        }
    }
`

import { notify, TextInputField, theme, ui } from "@pallet-hq/hegel"
import { getApolloInstance } from "apollo/client"
import { Formik } from "formik"
import Link from "next/link"
import { useState } from "react"
import * as Yup from "yup"
import OnboardingLayout from "components/OnboardingLayout"
import useAuthContext from "state/useAuthContext"
import useLoginModal from "state/useLoginModal"
import { handleLogin, ValidationErrors } from "utils/auth"
import { SubmitButton } from "./shared"
import ModalHeading from "./ModalHeading"

const PasswordForm = () => {
    const client = getApolloInstance()
    const { refresh } = useAuthContext()
    const { username, hideLoginModal } = useLoginModal()
    const [formErrors, setFormErrors] = useState<ValidationErrors[]>([])

    return (
        <Formik
            initialValues={{ password: "" }}
            validationSchema={Yup.object().shape({
                password: Yup.string().required("Password required"),
            })}
            onSubmit={async ({ password }) => {
                const errors = await handleLogin(username, password, true)
                if (errors.length > 0) setFormErrors(errors)
                else {
                    hideLoginModal()
                    await refresh()
                    await client.resetStore()
                    notify.success("Successfully logged in to Pallet!")
                }
            }}
        >
            {({ handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <TextInputField
                        name="password"
                        type="password"
                        label="Password"
                        autoComplete="current-password"
                        placeholder="Enter password"
                        sizePreset="large"
                        width="100%"
                        autoFocus
                    />
                    {formErrors.map((error, index) => (
                        <ui.ErrorMessage
                            key={index}
                            showError
                            message={error.message}
                        />
                    ))}
                    <SubmitButton loading={isSubmitting}>
                        Continue {"-->"}
                    </SubmitButton>
                    <Link
                        href="/forgot-password"
                        passHref
                        style={{
                            color: theme.colors.gray900,
                            fontWeight: "bold",
                            fontSize: theme.space.small,
                        }}
                    >
                        <div onClick={hideLoginModal}>Forgot Password?</div>
                    </Link>
                </form>
            )}
        </Formik>
    )
}

const LoginStep = () => (
    <OnboardingLayout
        heading="With your Pallet account, "
        subheading="you can share your profile, apply for jobs, join new collectives, and so much more."
        canGoBack
    >
        <ModalHeading text="Welcome back!" />
        <PasswordForm />
    </OnboardingLayout>
)

export default LoginStep

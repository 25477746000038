import { CheckboxField, MultiSelect } from "@pallet-hq/hegel"
import { JobBoardPreferenceQuery } from "__generated__/JobBoardPreferenceQuery"
import { getSelectOptions } from "components/inputs/Select/utils"
import LocationSelect from "components/inputs/Select/LocationSelect"

type JobPreferencesModalContentsProps = {
    data: JobBoardPreferenceQuery
}

const JobPreferencesModalContents = ({
    data,
}: JobPreferencesModalContentsProps) => {
    return (
        <>
            <LocationSelect
                name="locations2"
                label="Location"
                placeholder="Search or select locations"
                isMulti
                width="100%"
                isSearchable
                shouldCloseMenuOnScroll
                mt="medium"
                mb="medium"
            />
            <CheckboxField
                label="I am open to remote roles"
                name="openToRemote"
                mb="medium"
            />
            <MultiSelect
                name="experienceLevels"
                label="Experience Levels"
                description="How much experience do you have?"
                options={data.experience.map(o => ({
                    value: o.slug,
                    label: o.name,
                }))}
                placeholder="Select"
                mb="medium"
            />
            <MultiSelect
                name="jobTypes"
                width="100%"
                label="Relevant roles"
                description="What types of roles are you looking for?"
                options={getSelectOptions(data.jobTypes, "slug", "name")}
                placeholder="Search for roles"
                isSearchable
                pb="152px"
            />
        </>
    )
}

export default JobPreferencesModalContents

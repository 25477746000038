let CANONICAL_DOMAIN = ""
let CANONICAL_HOST = ""
let CANONICAL_URL = ""
let STRIPE_KEY_PUBLIC = ""
let SLACK_CLIENT_ID = ""
let REQUIRES_FALLBACK_FOR_SUBDOMAINS = false
let DEBUG_SSR = false
let ZAPIER_INVITE_LINK = ""
let MIXPANEL_ID = ""
let INTERCOM_ID = ""
let TYPESENSE_NODES = []
////////////////////////
// START ENVIRONMENTS //
////////////////////////

const is_new_production = process.env.NEXT_PUBLIC_ENV === "new-production"
const is_staging = process.env.NEXT_PUBLIC_ENV === "staging"
const is_preview = process.env.NEXT_PUBLIC_ENV === "preview"

//////////////////////
// END ENVIRONMENTS //
//////////////////////

if (is_new_production) {
    CANONICAL_DOMAIN = "pallet.com"
    CANONICAL_HOST = "app.pallet.com"
    CANONICAL_URL = "https://app.pallet.com"
    STRIPE_KEY_PUBLIC =
        "pk_live_51IIkCyHcVqM7N5749FFJwo4tpYotZdwkXPTlgtdjkMZ1D001PqtRlcjPAJGUQuIuiTrF3C19kPdvScow8ArVIkMC00GJ9eBxdC"
    SLACK_CLIENT_ID = "858165707682.2266632020851"
    REQUIRES_FALLBACK_FOR_SUBDOMAINS = false
    ZAPIER_INVITE_LINK =
        "https://zapier.com/developer/public-invite/141495/cee3c4fc8699515b1beffe9db98db68d/"
    MIXPANEL_ID = "35f334cb5867a38e6df92df8fd7b7646"
    INTERCOM_ID = "c9hw6mfh"
    TYPESENSE_NODES = [
        {
            host: "z7q4ce9u60x28jrsp.a1.typesense.net",
            port: 443,
            protocol: "https",
        },
    ]
} else if (is_staging || is_preview) {
    CANONICAL_DOMAIN = "pallet-staging.com"
    CANONICAL_HOST = "app.pallet-staging.com"
    CANONICAL_URL = "https://app.pallet-staging.com"
    STRIPE_KEY_PUBLIC =
        "pk_test_51IIkCyHcVqM7N574PdpxOXQik8kHZJNKDzHogoBcc7V0mPun6gvyEb0jkmYpjIil4V0FYaiLZWnApGS1BHPp8Yc200GvLXV6x4"
    SLACK_CLIENT_ID = "858165707682.2266632020851"
    REQUIRES_FALLBACK_FOR_SUBDOMAINS = is_preview
    ZAPIER_INVITE_LINK =
        "https://zapier.com/developer/public-invite/141825/1596798faf74b991bd273035596d99ed/"
    MIXPANEL_ID = "02fbbeb9d0b829502dddf77108bdce6a"
    INTERCOM_ID = "vte2zx7f"
    TYPESENSE_NODES = [
        {
            host: "uc67ikse9gh8rvfap-1.a1.typesense.net",
            port: 443,
            protocol: "https",
        },
    ]
} else {
    // implicit is_local
    CANONICAL_DOMAIN = "localhost:3000"
    CANONICAL_HOST = "localhost:3000"
    CANONICAL_URL = "http://localhost:3000"
    STRIPE_KEY_PUBLIC =
        "pk_test_51IIkCyHcVqM7N574PdpxOXQik8kHZJNKDzHogoBcc7V0mPun6gvyEb0jkmYpjIil4V0FYaiLZWnApGS1BHPp8Yc200GvLXV6x4"
    SLACK_CLIENT_ID = "858165707682.2266632020851"
    REQUIRES_FALLBACK_FOR_SUBDOMAINS = true
    ZAPIER_INVITE_LINK =
        "https://zapier.com/developer/public-invite/141825/1596798faf74b991bd273035596d99ed/"
    MIXPANEL_ID = "02fbbeb9d0b829502dddf77108bdce6a"
    INTERCOM_ID = "vte2zx7f"
    TYPESENSE_NODES = [
        {
            host: "localhost",
            port: 8108,
            protocol: "http",
        },
    ]
}

const config = {
    DEBUG_SSR,
    CANONICAL_DOMAIN,
    CANONICAL_URL,
    CANONICAL_HOST,
    PALLET_DOMAIN: "pallet.com",
    MARKETING_SITE: "https://www.pallet.com",
    CALENDLY_SPOTLIGHT_URL: "https://calendly.com/pallethq/getstarted",
    CALENDLY_CONTINGENCY_URL: "https://calendly.com/pallethq/boutique",
    CALENDLY_FAQ_URL: "https://calendly.com/pallethq/faq",
    CALENDLY_FREEMIUM_URL: "https://calendly.com/pallethq/getstarted",
    SERVER_URL: CANONICAL_URL + "/api/v1",
    SUPPORT_EMAIL: "team@pallet.com",
    SUPPORT_LINK: "https://support.pallet.com",
    PALLET_COLLECTIVE_SLUG: "collective",
    FULLSTORY_ID: "o-1HJT7W-na1",
    STRIPE_KEY_PUBLIC,
    IS_PRODUCTION: is_new_production,
    IS_STAGING: is_staging,
    IS_PREVIEW: is_preview,
    IS_LOCAL: !is_new_production && !is_staging && !is_preview,
    SLACK_CLIENT_ID,
    REQUIRES_FALLBACK_FOR_SUBDOMAINS,
    ZAPIER_INVITE_LINK,
    MIXPANEL_ID,
    INTERCOM_ID,
    TYPESENSE_NODES,
}

export default config

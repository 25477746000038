import constate from "constate"
import { useState } from "react"

const [FeedbackModalProvider, useFeedbackModal] = constate(() => {
    const [isVisible, setVisibility] = useState(false)

    return {
        isFeedbackModalVisible: isVisible,
        setFeedbackModalVisibility: setVisibility,
    }
})

export { FeedbackModalProvider }

export default useFeedbackModal

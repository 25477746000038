import {
    useState,
    SetStateAction,
    Dispatch,
    createContext,
    useContext,
} from "react"

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 *
 * https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/
 */
export function createCtx<A extends {} | null>() {
    const ctx = createContext<A | undefined>(undefined)
    const useCtx = (options?: { allowUndefined: boolean }) => {
        const c = useContext(ctx)
        if (c === undefined && !options?.allowUndefined)
            throw new Error("useCtx must be inside a Provider with a value")
        return c!
    }
    return [useCtx, ctx.Provider] as const // 'as const' makes TypeScript infer a tuple
}

/**
 * React hook that makes sure your state is immutable. Will shallow freeze state
 *
 * https://github.com/NoamRa/useFrozenState/blob/master/src/index.ts
 */
export function useFrozenState<S>(
    initialState: S
): [Readonly<S>, Dispatch<SetStateAction<S>>] {
    const [_state, _setState] = useState(initialState)
    return [Object.freeze(_state), _setState]
}

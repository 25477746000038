import constate from "constate"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

const blockedRoutes = [
    /^\/embed$/,
    /^\/welcome$/,
    /^\/login$/,
    /\/team\//,
    /^\/onboard\/.*/,
    /^\/management\/big-index.*/,
]

const isBlockedRoute = (path: string) => {
    return blockedRoutes.some(regex => regex.test(path))
}

const [HelpMenuProvider, useHelpMenu] = constate(() => {
    const [isVisible, setIsVisible] = useState(true)

    const router = useRouter()

    const maybeSetIsVisible = (_isVisible: boolean) => {
        // set is visible, but hide if the current route is a blocked route
        setIsVisible(!isBlockedRoute(router.pathname) && _isVisible)
    }

    // Set visibility when current path changes
    useEffect(() => {
        setIsVisible(!isBlockedRoute(router.pathname))
    }, [router.pathname])

    // When the path changes, but not shallowly (i.e. modals controlled by )
    useEffect(() => {
        const handleRouteChange = (
            url: string,
            { shallow }: { shallow: boolean }
        ) => {
            if (!shallow) setIsVisible(!isBlockedRoute(url))
        }

        router.events.on("routeChangeStart", handleRouteChange)
        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off("routeChangeStart", handleRouteChange)
        }
    }, [router.events])

    return {
        isVisible,
        setIsVisible: setIsVisible,
        maybeSetIsVisible,
    }
})

export { HelpMenuProvider }

export default useHelpMenu

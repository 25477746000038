/*
 * Detects if a link's href points to an external URL or not.
 * - If it is an internal URL, use the NextLink wrapper to enable client-side routing.
 * - If it is an external URL, use a standard HTML Anchor tag
 *
 * "External URLs, and any links that don't require a route navigation using /pages,
 * don't need to be handled with Link; use the anchor tag for such cases instead."
 * Source: https://nextjs.org/docs/api-reference/next/link
 */

import NextLink from "./NextLink"
import { ReactNode } from "react"

const SmartLink = ({
    href,
    children,
}: {
    href: string
    children: ReactNode
}) => {
    const regex = /^http/
    const isExternalLink = regex.test(href)

    return isExternalLink ? (
        <>{children}</>
    ) : (
        <NextLink href={href} passHref>
            {children}
        </NextLink>
    )
}

export default SmartLink

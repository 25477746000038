import styled from "@emotion/styled"
import { Theme, hexToHSL, mq, theme } from "@pallet-hq/hegel"
import { DesktopOnly } from "components/atoms"
import { ReactComponent as PalletLogo } from "components/icons/logos/pallet/logomark-white.svg"
import { ReactNode } from "react"
import { FiArrowLeft } from "react-icons/fi"
import useLoginModal, { LoginModalStep } from "state/useLoginModal"
import { QuarterCircleAnimatedPattern } from "@pallet/components/QuarterCircleAnimatedPattern"
import { useTheme } from "@emotion/react"

type OnboardingLayoutProps = {
    heading: string
    subheading: string
    children: ReactNode
    canGoBack?: boolean
}

const OnboardingLayout = (props: OnboardingLayoutProps) => {
    const { colors } = useTheme() as Theme
    const [h, s] = hexToHSL(colors.primary)
    const { setActiveStep } = useLoginModal()

    return (
        <Wrapper>
            <Left>
                {props.children}
                {props.canGoBack && (
                    <NavigationControls
                        onClick={() => setActiveStep(LoginModalStep.EMAIL)}
                    >
                        <FiArrowLeft size={24} />
                    </NavigationControls>
                )}
            </Left>
            <Right
                css={{
                    background: `hsl(${h},${s}%,10%)`,
                }}
            >
                <Overlay>
                    <Heading
                        css={{
                            color: `hsl(${h},${s}%,90%)`,
                        }}
                    >
                        {props.heading}
                        <Subheading>{props.subheading}</Subheading>
                    </Heading>
                    <QuarterCircleAnimatedPattern />
                </Overlay>
                <LogoWrapper>
                    <PalletLogo height={24} />
                </LogoWrapper>
            </Right>
        </Wrapper>
    )
}

export default OnboardingLayout

const Wrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
`

const Left = styled.div`
    ${mq({ width: ["100%", "60%"] })};
    max-width: 484px;
    margin: 0 auto;
    padding: 0 ${theme.space.small};
`

const Right = styled(DesktopOnly)`
    width: 40%;
    height: 100%;
    max-width: 548px;
`

const Overlay = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const Heading = styled.div`
    font-family: "MintGrotesk";
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    padding: ${theme.space.large};
    z-index: 1;
`

const Subheading = styled.span`
    font-family: "MintGrotesk";
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    font-style: italic;
`

const LogoWrapper = styled.div`
    position: fixed;
    bottom: 32px;
    right: 40px;
`

const NavigationControls = styled.div`
    position: absolute;
    top: 16px;
    left: 24px;
    cursor: pointer;
`

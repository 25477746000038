import { ReactElement, ReactNode } from "react"
import { AppProps } from "next/app"
import { theme } from "@pallet-hq/hegel"
import mixpanel from "mixpanel-browser"
import Head from "components/Head"
import config from "config"
import { useMixpanelTracking } from "utils/hooks/useMixpanelTracking"

// Global styles
import "@pallet-hq/hegel/lib/styles.css"
import "utils/styles/global.css"
import "react-toastify/dist/ReactToastify.min.css"
import "@tremor/react/dist/esm/tremor.css"

// Providers
import { ApolloProvider } from "@apollo/client"
import { Provider as HegelProvider } from "@pallet-hq/hegel"
import { ThemeProvider } from "@emotion/react"
import { AuthContextProvider } from "state/useAuthContext"
import { JobPreferencesProvider } from "state/jobPreferences/useJobPreferences"
import { LoginModalProvider } from "state/useLoginModal"
import { SignupPromptProvider } from "state/useSignupPrompt"
import { FeatureFlagsProvider } from "state/useFeatureFlags"
// Intercom
import IntercomProvider from "state/IntercomProvider"

// Modals, global UI elements
import JobPreferencesModal from "components/Modals/JobPreferencesModal"
import SignUpPrompt from "components/SignUpPrompt"
import LoginModal from "components/Modals/LoginModal"
import { useHasMounted } from "utils/hooks"

// Polyfills
import "core-js/features/object/entries"
import "core-js/features/object/from-entries"

// Other
import { useApollo } from "apollo/client"
import HelpMenu from "components/HelpMenu"
import { HelpMenuProvider } from "state/useHelpMenu"
import { NextPage } from "next"
import { PalletContext } from "utils/router"
import FullStory from "react-fullstory"

mixpanel.init(config.MIXPANEL_ID, {
    api_host: "https://api.mixpanel.com",
})

type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement, palletContext?: PalletContext) => ReactNode
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
    const isMounted = useHasMounted()
    useMixpanelTracking()

    const getLayout = Component.getLayout ?? (page => page)

    return (
        <>
            <Head />
            <Providers pageProps={pageProps}>
                {getLayout(
                    <Component {...pageProps} />,
                    pageProps.palletContext
                )}
                {isMounted && (
                    <>
                        <JobPreferencesModal />
                        <SignUpPrompt />
                        <LoginModal />
                        <HelpMenu />
                        <FullStory org={config.FULLSTORY_ID} />
                    </>
                )}
            </Providers>
        </>
    )
}

const Providers = ({
    pageProps,
    children,
}: {
    pageProps: any
    children: ReactNode
}) => {
    const apolloClient = useApollo(pageProps.initialApolloState)

    return (
        <ApolloProvider client={apolloClient}>
            <FeatureFlagsProvider>
                <HegelProvider>
                    <HelpMenuProvider>
                        <IntercomProvider>
                            <AuthContextProvider>
                                <JobPreferencesProvider>
                                    <SignupPromptProvider>
                                        <LoginModalProvider>
                                            <ThemeProvider theme={theme}>
                                                {children}
                                            </ThemeProvider>
                                        </LoginModalProvider>
                                    </SignupPromptProvider>
                                </JobPreferencesProvider>
                            </AuthContextProvider>
                        </IntercomProvider>
                    </HelpMenuProvider>
                </HegelProvider>
            </FeatureFlagsProvider>
        </ApolloProvider>
    )
}

export default App

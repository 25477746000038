import { gql } from "@apollo/client"

export const CompanySubscriptionFragment = gql`
    fragment Company_subscription on CompanySubscriptionType {
        id
        uuid
        subscriptionType
        approvalStatus
        additionalStatus
        name
        endsAt
        renewsAt
        user {
            id
            fullName
            email
        }
        company {
            id
            name
            website
            slug
            image
        }
    }
`

export const PENDING_SUBSCRIPTIONS_FRAGMENT = gql`
    fragment PendingSubscriptions_applylist on ApplylistType {
        id
        pendingSubscriptions: subscriptions(
            first: $offset
            after: $cursor
            approvalStatus: $pendingSubscriptionStatus
        ) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    ...Company_subscription
                }
            }
        }
    }
    ${CompanySubscriptionFragment}
`

export const ACTIVE_SUBSCRIPTIONS_FRAGMENT = gql`
    fragment ActiveSubscriptions_applylist on ApplylistType {
        id
        activeSubscriptions: subscriptions(
            first: $offset
            after: $cursor
            approvalStatus: $activeSubscriptionStatus
        ) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    ...Company_subscription
                }
            }
        }
    }
    ${CompanySubscriptionFragment}
`

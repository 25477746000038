import { Option } from "@pallet-hq/hegel/lib/UIComponents/SelectModules/Types"
import { ReactNode } from "react"

export type SelectOption = Option
export type SelectOptions = Option[]

/*
 * Prepare an array to be used in our Select Inputs.
 * `other` allows you to store values beyond value/label that
 * be used for auxiliary purposes.
 */
export const getSelectOptions = (
    arr: any[] | undefined,
    value: string,
    label: string,
    abbreviatedLabel?: string,
    getIcon?: (element: any) => ReactNode,
    other?: string[]
): SelectOptions => {
    if (!arr) {
        return []
    } else {
        return arr.map(element => ({
            value: element[value],
            label: element[label],
            other: Object.assign(
                {},
                ...(other != undefined
                    ? other.map(x => ({ [x]: element[x] }))
                    : [])
            ),
            abbreviatedLabel: abbreviatedLabel ? element[abbreviatedLabel] : "",
            icon: getIcon ? getIcon(element) : undefined,
        }))
    }
}

/*
 * Prepare a relay edge connection to be used in our Select Inputs.
 */
export const getSelectOptions2 = (
    edges: any[],
    value: string,
    label: string,
    abbreviatedLabel?: string,
    other?: string[]
) => {
    return edges.map(edge => ({
        value: edge.node[value],
        label: edge.node[label],
        abbreviatedLabel: abbreviatedLabel ? edge.node[abbreviatedLabel] : "",
        other: Object.assign(
            {},
            ...(other != undefined
                ? other.map(x => ({ [x]: edge.node[x] }))
                : [])
        ),
    }))
}

export const convertCheckboxesToValues = (options: {
    [slug: string]: boolean
}) => {
    return Object.entries(options)
        .filter(([, value]) => value)
        .map(([key]) => key)
}

export const setUpCheckboxValues = (objects: any[], key: string) => {
    // returns an object with each key being set to false
    return objects.reduce((checkboxValues, object) => {
        return {
            ...checkboxValues,
            [object[key]]: false,
        }
    }, {})
}

export const convertOptionToValue = (
    option: SelectOption
): SelectOption["value"] => {
    return option.value
}

export const convertOptionsToValues = (
    options: SelectOption[]
): SelectOption["value"][] => {
    return options.map(option => convertOptionToValue(option))
}

export const convertOptionsToSlugs = convertOptionsToValues

export const convertBooleanRadioRowToValue = (
    value: string | null
): boolean | null => {
    return value ? value === "true" : null
}

import { theme } from "@pallet-hq/hegel"
import { debounce } from "lodash"
import { useEffect, useState } from "react"

export const useMountedMediaQuery = () => {
    const [screenWidth, setScreenWidth] = useState<number | undefined>(
        undefined
    )

    useEffect(() => {
        if (!screenWidth) setScreenWidth(window.innerWidth)
        const debouncedUpdateScreen = debounce(() => {
            setScreenWidth(window.innerWidth)
        }, 250) // 250 ms
        window.addEventListener("resize", debouncedUpdateScreen)
        return () => window.removeEventListener("resize", debouncedUpdateScreen)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return screenWidth
}

type Size = {
    isMobile: boolean
    isTablet: boolean
    isDesktop: boolean
}

export const useScreenSize = (): Size => {
    const stripPx = (x: string): number => parseInt(x.replace(/\D/g, ""))
    const width = useMountedMediaQuery()
    if (!width) {
        return {
            isMobile: false,
            isTablet: false,
            isDesktop: true,
        }
    }
    return {
        isMobile: width < stripPx(theme.breakpoints.mobile),
        isTablet:
            width >= stripPx(theme.breakpoints.mobile) &&
            width < stripPx(theme.breakpoints.desktop),
        isDesktop: width >= stripPx(theme.breakpoints.desktop),
    }
}

export const DEVELOPMENT_URLS = ["localhost"]
export const PRODUCTION_URLS = [
    "pallet.vercel.app",
    "pallet-staging.com",
    "pallet.com",
]

export const ROUTES = {
    JOBS: "/jobs",
    ONBOARDING_WORK: "/onboard/work",
    ONBOARDING_PREFERENCES: "/onboard/preferences",
    ONBOARDING_SIGNUP: "/onboard/signup",
    ONBOARDING_LOGIN: "/onboard/login",
}

import styled from "@emotion/styled"
import { Box } from "@pallet-hq/hegel"

type ModalHeadingProps = {
    text: string
}

const ModalHeading = ({ text }: ModalHeadingProps) => {
    return (
        <Box mb="large">
            <Headline>{text}</Headline>
        </Box>
    )
}

export default ModalHeading

const Headline = styled.div`
    font-family: "MintGrotesk";
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.009em;
`

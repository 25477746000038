import { gql } from "@apollo/client"

export const LOCATIONS_QUERY = gql`
    query LocationsQuery($searchInput: String!, $first: Int!) {
        locations2(searchInput: $searchInput, first: $first) {
            edges {
                node {
                    id
                    geonameId
                    longLabel
                    shortLabel
                    locationType
                }
            }
        }
    }
`

import { FieldPolicy } from "@apollo/client"
import { isEqual, uniqWith } from "lodash"

/**
 * Field Policy for paginated data ({pageInfo: ..., edges: [...]}).

 * @returns FieldPolicy with merge function
 */

export const PaginatedDataFieldPolicy = (
    keyArgs: any = false
): FieldPolicy => ({
    keyArgs, // dont make a new cache object for any query args
    merge(existingData = {}, incoming) {
        return {
            ...existingData,
            ...incoming,
            /**
             * we are using a lodash function to make sure the that the edges
             * returned by the merge function are unique
             * this is to solve the error of merging after a filter change + refetch
             * in the future we only want the merge to append edges on a fetchMore call (pagination )
             */

            edges: uniqWith(
                [...(existingData.edges || []), ...(incoming?.edges ?? [])],
                isEqual
            ),
        }
    },
})

import { useEffect, useState } from "react"
import { hasWindow } from "utils/window"

export const useSessionStorage = (
    key: string
): [string | null, (value: string) => void] => {
    if (!hasWindow()) {
        return [null, () => {}]
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [state, setState] = useState(() => {
        try {
            return sessionStorage.getItem(key)
        } catch {
            // If user is in private mode or has storage restriction, sessionStorage can throw.
            return null
        }
    })

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        try {
            state && sessionStorage.setItem(key, state)
        } catch {
            // If user is in private mode or has storage restriction, sessionStorage can throw.
        }
    })

    return [state, setState]
}
